import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Flex,
    Text,
    useToast
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import apiBackend from '../../../../shared/apis';
import Button from '../../../../shared/components/Button';
import Form from '../../../../shared/components/Form';
import Input from '../../../../shared/components/Input';
import UploadPhoto from '../../../../shared/components/UploadPhoto';
import { HTTP_RESPONSE } from '../../../../shared/constants';
import { useLayout } from '../../../../shared/hooks/layout';
import TextUtils from '../../../../shared/utils/TextUtils';

const ReceptionistRegisterPage: React.FC = () => {
    const formRef = useRef<FormHandles>(null);
    const { user } = useLayout();
    const toast = useToast();
    const navigate = useNavigate();
    const [dataUri, setDataUri] = useState('');

    const [receptionistLength, setReceptionistLength] = useState<number>(0);

    const handleSubmit = useCallback(() => {
        const data = formRef.current?.getData();

        apiBackend(user?.token)
            .post('/users', {
                ...data,
                email: '',
                login: TextUtils.removeCharacters(data?.whatsapp || ''),
                type: 'RECEPTIONIST',
                photo_url: dataUri
            })
            .then(response => {
                const { status, data } = response;

                if (status === HTTP_RESPONSE.STATUS.CREATED) {
                    toast({
                        title: 'Cadastro Realizado',
                        description: '',
                        status: 'success',
                        duration: 4000,
                        isClosable: true
                    });

                    navigate('/receptionists');
                }

                if (status === HTTP_RESPONSE.STATUS.BAD_REQUEST) {
                    toast({
                        title: 'Atenção',
                        description: data.message,
                        status: 'warning',
                        duration: 4000,
                        isClosable: true
                    });
                }
            });
    }, [formRef, user, dataUri]);

    useEffect(() => {
        apiBackend(user?.token)
            .get('/users?type=RECEPTIONIST')
            .then(response => {
                const { status, data } = response;

                if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                    setReceptionistLength(data.length);
                }
            });
    }, [user]);

    return (
        <Form ref={formRef}>
            <Flex width="100%" flexDirection="column">
                <Breadcrumb
                    mb="16px"
                    fontWeight="500"
                    color="gray.900"
                    fontSize="14px"
                >
                    <BreadcrumbItem>
                        <BreadcrumbLink href="/dashboard">
                            Início
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem>
                        <BreadcrumbLink href="/Receptionists">
                            Recepcionista
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem isCurrentPage>
                        <BreadcrumbLink href="/Receptionists/new">
                            Novo Recepcionista
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>

                {user?.plan?.num_receptionists !== '-1' &&
                    receptionistLength >=
                        Number(user?.plan?.num_receptionists) && (
                        <Flex
                            width="100%"
                            backgroundColor="yellow.500"
                            justifyContent="center"
                            fontWeight="500"
                            mb="8px"
                            py="8px"
                            px="8px"
                            onClick={() => navigate('/plans')}
                            fontSize={['12px', '14px']}
                            cursor="pointer"
                        >
                            <Text>
                                Você excedeu o número máximo de recepcionistas.
                                Clique aqui para fazer upgrade.
                            </Text>
                        </Flex>
                    )}

                <Flex
                    width="100%"
                    flexDirection="column"
                    color="gray.900"
                    backgroundColor="white"
                    p="32px"
                >
                    <Text fontWeight="500" fontSize="14px" mb="13px">
                        Novo Recepcionista
                    </Text>

                    <Flex
                        width="100%"
                        justifyContent="space-between"
                        flexDirection={['column', 'column', 'row']}
                    >
                        <Flex
                            width={['100%', '100%', '48%']}
                            flexDirection="column"
                        >
                            <Flex
                                width="100%"
                                justifyContent="space-between"
                                flexDirection={['column', 'column', 'row']}
                            >
                                <Flex
                                    width={['100%', '100%', '48%']}
                                    flexDirection="column"
                                >
                                    <Input name="name" label="Nome" />
                                </Flex>
                            </Flex>

                            <Flex
                                width="100%"
                                justifyContent="space-between"
                                flexDirection={['column', 'column', 'row']}
                            >
                                <Flex
                                    width={['100%', '100%', '48%']}
                                    flexDirection="column"
                                >
                                    <Input
                                        name="whatsapp"
                                        label="Whatsapp/Login"
                                        mask="(99) 99999-9999"
                                    />
                                </Flex>

                                <Flex
                                    width={['100%', '100%', '48%']}
                                    flexDirection="column"
                                >
                                    <Input
                                        name="password"
                                        label="Senha"
                                        type="password"
                                    />
                                </Flex>
                            </Flex>
                        </Flex>

                        <Flex
                            width={['100%', '100%', '48%']}
                            flexDirection="column"
                            alignItems="center"
                        >
                            <UploadPhoto setFunction={setDataUri} />
                        </Flex>
                    </Flex>

                    {(user?.plan.num_receptionists === '-1' ||
                        receptionistLength <
                            Number(user?.plan.num_receptionists)) && (
                        <Flex width="100%" justifyContent="center" mt="24px">
                            <Button
                                title="Cadastrar"
                                backgroundColor="green.500"
                                color="white"
                                width="160px"
                                borderRadius="4px"
                                py="8px"
                                onClick={() => handleSubmit()}
                            />
                        </Flex>
                    )}
                </Flex>
            </Flex>
        </Form>
    );
};

export default ReceptionistRegisterPage;
