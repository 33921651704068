import React, { useCallback, useState } from 'react';
import Dropzone from 'react-dropzone';

import { Flex, Box, Avatar, Image } from '@chakra-ui/react';

import apiBackend from '../../apis';
import { useLayout } from '../../hooks/layout';

interface IProps {
    setUploadedImage: any;
    width?: any;
    height?: any;
    url: string;
    photo?: any;
}

const AvatarUpload: React.FC<IProps> = ({
    setUploadedImage,
    width = '80px',
    height = '80px',
    url = '',
    photo = ''
}) => {
    const { user } = useLayout();
    const [uploadedPhoto, setUploadPhoto] = useState(photo);

    const handleOnUploadImage = useCallback(
        async (file: any) => {
            try {
                const image = {
                    key: Math.random().toString(36).substr(2, 9),
                    id: null,
                    file: file[0],
                    name: file[0].name,
                    preview: URL.createObjectURL(file[0]),
                    progress: 0,
                    uploaded: false,
                    error: false,
                    url: null,
                    is_cover: false
                };

                setUploadedImage(image);

                const data = new FormData();

                let response: any = null;

                data.append('file', image.file, image.name);

                response = await apiBackend(user?.token).post(url, data, {
                    onUploadProgress: (e: any) => {
                        const progress = parseInt(
                            Math.round((e.loaded * 100) / e.total).toString(),
                            10
                        );

                        setUploadedImage({
                            ...image,
                            progress
                        });
                    }
                });

                setUploadedImage({
                    ...image,
                    id: response.data[0],
                    uploaded: true,
                    url: response?.data?.url
                });

                setUploadPhoto(response?.data?.url);
            } catch (error) {
                setUploadedImage(oldState => {
                    return {
                        ...oldState,
                        error: true
                    };
                });
            }
        },
        [setUploadedImage, user]
    );

    return (
        <Dropzone
            accept={{
                image: ['*']
            }}
            onDropAccepted={handleOnUploadImage}
        >
            {({ getRootProps, getInputProps }) => (
                <Flex
                    flexDirection="column"
                    alignItems="center"
                    {...getRootProps()}
                    outline="none"
                >
                    <input {...getInputProps()} />
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        width={width}
                        height={height}
                        _hover={{
                            cursor: 'pointer'
                        }}
                    >
                        {!uploadedPhoto && <Avatar />}
                        {uploadedPhoto && (
                            <Image
                                src={uploadedPhoto}
                                width="48px"
                                height="48px"
                                borderRadius="50%"
                            />
                        )}
                    </Box>
                </Flex>
            )}
        </Dropzone>
    );
};

export default AvatarUpload;
