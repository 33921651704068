import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiAlertTriangle } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router-dom';
import { ZodError, z } from 'zod';

import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Flex,
    Icon,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Stack,
    Text,
    Button as ButtonChakra,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import apiBackend from '../../../../shared/apis';
import Button from '../../../../shared/components/Button';
import Checkbox from '../../../../shared/components/Checkbox';
import Form from '../../../../shared/components/Form';
import Input from '../../../../shared/components/Input';
import { MaxCapacity } from '../../../../shared/components/MaxCapacity';
import Select from '../../../../shared/components/Select';
import { HTTP_RESPONSE } from '../../../../shared/constants';
import { useLayout } from '../../../../shared/hooks/layout';
import GuestData from './components/GuestData';
import Successful from './components/Successful';

const GuestRegisterPage: React.FC = () => {
    const formRef = useRef<FormHandles>();
    const toast = useToast();

    const { id } = useParams();
    const { user } = useLayout();
    const navigate = useNavigate();

    const [value, setValue] = useState('');
    const [invalidNumber, setInvalidNumber] = useState(true);

    const [event, setEvent] = useState(null as any);
    const { onOpen, isOpen, onClose } = useDisclosure();
    const [numAdults, setNumAdults] = useState(1);
    const [numChildrens, setNumChildrens] = useState(0);

    const [firstTime, setFirstTime] = useState(true);

    const [isSuccessful, setIsSuccesfull] = useState(false);

    const loadData = useCallback(() => {
        apiBackend(user?.token)
            .get(`/events/${id}`)
            .then(response => {
                const { status, data } = response;

                if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                    setEvent(data);
                    if (data.status === 'FINISHED') onOpen();
                }
            });
    }, [user, id]);

    const handleSubmit = useCallback(async () => {
        const data: any = formRef.current?.getData();

        const dataSchema = z.object({
            main_adult: z.string().min(1, {
                message: 'Preencha o nome do responsável'
            }),
            main_whatsapp: z.string().min(1, {
                message: 'Preencha o número do responsável'
            }),
            adults:
                Number(data.num_adults) > 1
                    ? z
                          .array(
                              z.string().min(1, {
                                  message: 'Preencha o nome de todos os adultos'
                              })
                          )
                          .min(Number(data.num_adults) - 1, {
                              message: 'Preencha o nome de todos os adultos'
                          })
                    : z.array(z.string()).optional(),
            telephones:
                Number(data.num_adults) > 1
                    ? z
                          .array(
                              z.string().min(1, {
                                  message:
                                      'Preencha o número de todos os adultos'
                              })
                          )
                          .min(Number(data.num_adults) - 1, {
                              message: 'Preencha o número de todos os adultos'
                          })
                    : z.array(z.string()).optional(),
            childrens:
                Number(data.num_childrens) > 0
                    ? z
                          .array(
                              z.string().min(1, {
                                  message:
                                      'Preencha o nome de todas as crianças'
                              })
                          )
                          .min(Number(data.num_childrens), {
                              message: 'Preencha o nome de todas as crianças'
                          })
                    : z.array(z.string()).optional(),
            ages:
                Number(data.num_childrens) > 0
                    ? z.array(z.string()).min(Number(data.num_childrens), {
                          message: 'Preencha a idade de todas as crianças'
                      })
                    : z.array(z.string()).optional()
        });

        try {
            dataSchema.parse(data);
        } catch (e) {
            if (e instanceof ZodError) {
                console.log(e.errors);
                toast({
                    title: 'Campo Obrigatório',
                    description: e.errors[0].message,
                    status: 'error',
                    duration: 4000,
                    isClosable: true
                });
            }

            return;
        }

        if (event?.status === 'FINISHED') {
            onOpen();
            return;
        }

        await apiBackend(user?.token)
            .post(`/events/${id}/guests`, {
                ...data,
                first_time: firstTime
            })
            .then(response => {
                const { status } = response;

                if (status === HTTP_RESPONSE.STATUS.CREATED) {
                    setIsSuccesfull(true);
                }
            });
    }, [user, event, formRef, id, formRef, firstTime]);

    useEffect(() => {
        loadData();

        const defaultDDD = localStorage.getItem('@recept:ddd');

        if (defaultDDD) {
            setValue(`(${defaultDDD})`);
        }
    }, [loadData]);

    return (
        <Form ref={formRef}>
            <Flex width="100%" flexDirection="column">
                <Flex width="100%" justifyContent="space-between">
                    <Breadcrumb
                        mb="16px"
                        fontWeight="500"
                        color="gray.900"
                        fontSize="12px"
                    >
                        <BreadcrumbItem>
                            <BreadcrumbLink href="/dashboard">
                                Início
                            </BreadcrumbLink>
                        </BreadcrumbItem>

                        <BreadcrumbItem>
                            <BreadcrumbLink href={`/events/${event?.id}`}>
                                {event?.name}
                            </BreadcrumbLink>
                        </BreadcrumbItem>

                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink
                                href={`/events/${event?.id}/guests`}
                            >
                                Novo Convidado
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>

                    <Flex
                        display={['flex', 'flex', 'none']}
                        fontSize="14px"
                        fontWeight="600"
                        color="green.500"
                        onClick={() => navigate(`/dashboard`)}
                    >
                        <Text>{`Voltar>`}</Text>
                    </Flex>
                </Flex>

                <MaxCapacity event={event} />

                <Flex
                    width="100%"
                    flexDirection="column"
                    color="gray.900"
                    backgroundColor="white"
                    p="24px"
                    fontSize="16px"
                >
                    <Text fontWeight="500" fontSize="16px" mb="13px">
                        Novo Convidado
                    </Text>

                    <Flex
                        width="100%"
                        justifyContent="space-between"
                        flexDirection={['column', 'column', 'row']}
                    >
                        <Flex
                            width={['100%', '100%', '48%']}
                            flexDirection="column"
                        >
                            <Select
                                width="200px"
                                name="num_adults"
                                label="Nº de adultos"
                                onChange={e =>
                                    setNumAdults(Number(e.currentTarget.value))
                                }
                                fontSize="16px"
                            >
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                            </Select>

                            <Select
                                width="200px"
                                name="num_childrens"
                                label="Nº de crianças"
                                onChange={e =>
                                    setNumChildrens(
                                        Number(e.currentTarget.value)
                                    )
                                }
                                fontSize="16px"
                            >
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                            </Select>

                            <Flex
                                width="100%"
                                flexDirection="column"
                                backgroundColor={'gray.100'}
                                p="16px"
                                mb="8px"
                            >
                                <Input
                                    name="main_adult"
                                    label="Nome do Adulto Principal"
                                    fontSize="16px"
                                    backgroundColor={'white'}
                                    color="green.500"
                                    size="md"
                                />

                                <Flex position="relative">
                                    <Input
                                        name="main_whatsapp"
                                        label="Whatsapp"
                                        fontSize="16px"
                                        type="tel"
                                        backgroundColor={'white'}
                                        size="md"
                                        mask={'(99) 99999-9999'}
                                        value={value}
                                        onChange={e => {
                                            setValue(e.currentTarget.value);

                                            const regex = new RegExp(
                                                /\(\d{2,}\) \d{4,}-\d{4}/
                                            );

                                            if (
                                                !regex.test(
                                                    e.currentTarget.value
                                                )
                                            ) {
                                                setInvalidNumber(true);
                                            } else {
                                                setInvalidNumber(false);
                                            }
                                        }}
                                    />

                                    {invalidNumber && (
                                        <Icon
                                            as={FiAlertTriangle}
                                            title="Numero não é valido"
                                            position="absolute"
                                            right="10px"
                                            top="38px"
                                            color="yellow.500"
                                        />
                                    )}
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>

                    <Flex width="100%" flexDirection="column" fontSize="16px">
                        {numAdults > 1 &&
                            Array.from({ length: numAdults - 1 }).map(
                                (x, i) => (
                                    <GuestData key={`A${i}`} index={i + 1} />
                                )
                            )}

                        {Array.from({ length: numChildrens }).map((x, i) => (
                            <GuestData
                                key={`C${i}`}
                                index={i + 1}
                                isAdult={false}
                            />
                        ))}
                    </Flex>

                    <Flex width="100%" mt="8px" flexDirection="column">
                        <Text fontSize="16px" fontWeight="500">
                            Ja estiveram na casa antes?
                        </Text>
                        <Stack
                            direction={['column', 'column', 'row']}
                            spacing={['2', '2', '8']}
                        >
                            <Checkbox
                                name="unity"
                                colorScheme="green"
                                isChecked={firstTime ? false : true}
                                onChange={() => setFirstTime(false)}
                            >
                                <Text fontSize="16px">Sim</Text>
                            </Checkbox>

                            <Checkbox
                                name="register_interaction"
                                colorScheme="green"
                                isChecked={firstTime ? true : false}
                                onChange={() => setFirstTime(true)}
                            >
                                <Text fontSize="16px">Não</Text>
                            </Checkbox>
                        </Stack>
                    </Flex>

                    <Flex width="100%" justifyContent="center" mt="24px">
                        <Button
                            title="Cadastrar"
                            backgroundColor="green.500"
                            color="white"
                            width="160px"
                            borderRadius="4px"
                            py="8px"
                            onClick={() => handleSubmit()}
                        />
                    </Flex>
                </Flex>
            </Flex>

            {isSuccessful && <Successful />}

            {event?.status === 'FINISHED' && (
                <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
                    <ModalOverlay />
                    <ModalContent>
                        <ModalBody pb="8px">
                            <Flex
                                width="400px"
                                height="100px"
                                flexDirection="column"
                                alignItems="center"
                                backgroundColor="white"
                                px="24px"
                                py="8px"
                            >
                                <Text
                                    fontSize="18px"
                                    color="green.500"
                                    fontWeight="600"
                                >
                                    Este evento já foi encerrado.
                                </Text>

                                <Text color="gray.600" fontWeight="500">
                                    Não é possível adicionar convidados.
                                </Text>
                            </Flex>

                            <Flex
                                width="100%"
                                flexDirection="row"
                                justifyContent="center"
                                mt="16px"
                                mb="8px"
                                fontWeight="500"
                            >
                                <ButtonChakra
                                    width="48%"
                                    backgroundColor="green.500"
                                    color="white"
                                    onClick={() => {
                                        onClose();
                                        navigate(`/events/${event.id}`);
                                    }}
                                    outline="none"
                                    _hover={{
                                        backgroundColor: 'green.500'
                                    }}
                                >
                                    Fechar
                                </ButtonChakra>
                            </Flex>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            )}
        </Form>
    );
};

export default GuestRegisterPage;
