import { format } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiCalendar, FiTrash } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { ZodError, z } from 'zod';

import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Fade,
    Flex,
    Icon,
    Text,
    useToast
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import apiBackend from '../../../../shared/apis';
import Button from '../../../../shared/components/Button';
import Calender from '../../../../shared/components/Calander';
import Form from '../../../../shared/components/Form';
import Input from '../../../../shared/components/Input';
import Select from '../../../../shared/components/Select';
import SelectMultiple from '../../../../shared/components/SelectMultiple';
import TextArea from '../../../../shared/components/TextArea';
import { HTTP_RESPONSE } from '../../../../shared/constants';
import { useLayout } from '../../../../shared/hooks/layout';

// import { Container } from './styles';

const EventRegisterPage: React.FC = () => {
    const { user, currentUnity } = useLayout();

    const navigate = useNavigate();
    const toast = useToast();

    const formRef = useRef<FormHandles>(null);

    const [date, setDate] = useState(new Date());
    const [receptionists, setReceptionists] = useState<any[]>([]);
    const [selectedReceptionists, setSelectedReceptionists] = useState<any[]>(
        []
    );

    const [searches, setSearches] = useState([]);

    const [showCalendar, setShowCalendar] = useState(false);

    const handleChangeDeliveryDate = useCallback((value: Date) => {
        setDate(value);
        setShowCalendar(false);
    }, []);

    const handleSearchReceptionists = useCallback(async () => {
        const { status, data } = await apiBackend(user?.token).get(
            '/users?type=RECEPTIONIST'
        );

        if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
            if (data) {
                setReceptionists(data);

                return data.map(data => ({
                    id: data.id,
                    text: data.name,
                    value: data.id
                }));
            }
        }

        return {};
    }, [user]);

    const handleSetReceptionists = useCallback(
        (ids: string[]): void => {
            const updatedSelectedReceptionists = ids.map(id =>
                receptionists.find(item => item.id === id)
            );

            setSelectedReceptionists(updatedSelectedReceptionists);
        },
        [receptionists]
    );

    const handleRemoveReceptionist = useCallback((id: string) => {
        setSelectedReceptionists(oldState => {
            const updatedState = oldState.filter(item => item.id !== id);

            return [...updatedState];
        });
    }, []);

    const handleSubmit = useCallback(() => {
        const data = formRef.current?.getData();

        const dataSchema = z.object({
            name: z.string().min(1, { message: 'Informe o nome do evento' }),
            type: z.string().min(1, { message: 'Informe o tipo do evento' }),
            honored_name: z
                .string()
                .min(1, { message: 'Informe o nome do homenageado' }),
            num_payers: z
                .string()
                .min(1, { message: 'Informe o número de pagantes' }),
            date: z.string().min(1, { message: 'Informe a data do evento' }),
            start_hour: z
                .string()
                .min(1, { message: 'Informe o horário de início' }),
            end_hour: z
                .string()
                .min(1, { message: 'Informe o horário de encerramento' }),
            contractor_name_1: z
                .string()
                .min(1, { message: 'Informe o nome do contratante' }),
            contractor_telephone_1: z
                .string()
                .min(1, { message: 'Informe o telefone do contratante' })
        });

        try {
            dataSchema.parse(data);
        } catch (e) {
            if (e instanceof ZodError) {
                toast({
                    title: 'Campo Obrigatório',
                    description: e.errors[0].message,
                    status: 'error',
                    duration: 4000,
                    isClosable: true
                });
            }

            return;
        }

        if (selectedReceptionists.length === 0) {
            toast({
                title: 'Campo Obrigatório',
                description: 'Selecione ao menos uma recepcionista',
                status: 'error',
                duration: 4000,
                isClosable: true
            });
            return;
        }

        apiBackend(user?.token)
            .post('/events', {
                ...data,
                date: format(date, 'yyyy-MM-dd'),
                receptionists: selectedReceptionists,
                unity_id: currentUnity.id
            })
            .then(response => {
                const { status } = response;

                if (status === HTTP_RESPONSE.STATUS.CREATED) {
                    toast({
                        title: 'Cadastro Realizado',
                        description: '',
                        status: 'success',
                        duration: 4000,
                        isClosable: true
                    });

                    navigate('/events');
                } else {
                    toast({
                        title: 'Ocorreu um erro',
                        description: '',
                        status: 'error',
                        duration: 4000,
                        isClosable: true
                    });
                }
            });
    }, [user, selectedReceptionists, currentUnity, date]);

    const loadSearches = useCallback(() => {
        apiBackend(user?.token)
            .get('/searches')
            .then(response => {
                const { status, data } = response;

                if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                    setSearches(data);
                }
            });
    }, []);

    useEffect(() => {
        loadSearches();
    }, [loadSearches]);

    return (
        <Form ref={formRef}>
            <Flex width="100%" flexDirection="column">
                <Breadcrumb
                    mb="16px"
                    fontWeight="500"
                    color="gray.900"
                    fontSize="14px"
                >
                    <BreadcrumbItem>
                        <BreadcrumbLink href="/dashboard">
                            Início
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem>
                        <BreadcrumbLink href="/events">Eventos</BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem isCurrentPage>
                        <BreadcrumbLink href="/events/new">
                            Novo Evento
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>

                <Flex
                    width="100%"
                    flexDirection="column"
                    color="gray.900"
                    backgroundColor="white"
                    p="32px"
                >
                    <Text fontWeight="500" fontSize="14px" mb="13px">
                        Novo Evento
                    </Text>

                    <Input name="name" label="Nome do Evento" isRequired />

                    <Flex
                        width="100%"
                        justifyContent="space-between"
                        flexDirection={['column', 'column', 'row']}
                    >
                        <Flex
                            width={['100%', '100%', '48%']}
                            flexDirection="column"
                        >
                            <Select
                                name="type"
                                label="Tipo de Festa"
                                placeholder="Selecione o tipo de festa"
                                isRequired
                            >
                                <option>Aniversário</option>
                                <option>Festa Infantil</option>
                                <option>15 Anos</option>
                                <option>Batizado</option>
                                <option>Bodas</option>
                                <option>Casamento</option>
                            </Select>

                            <Flex position="relative">
                                <Input
                                    name="date"
                                    label="Data do Evento"
                                    value={format(date, 'dd/MM/yy')}
                                    onClick={() =>
                                        setShowCalendar(!showCalendar)
                                    }
                                    isRequired
                                />

                                <Flex
                                    position="absolute"
                                    bottom="24px"
                                    right="8px"
                                    cursor="pointer"
                                    onClick={() =>
                                        setShowCalendar(!showCalendar)
                                    }
                                >
                                    <Icon as={FiCalendar} color="green.500" />
                                </Flex>
                            </Flex>

                            <Fade in={showCalendar}>
                                <Flex display={showCalendar ? 'flex' : 'none'}>
                                    <Calender
                                        isErrored={false}
                                        selectedDate={date}
                                        handleDateChange={
                                            handleChangeDeliveryDate
                                        }
                                        handleMonthChange={undefined}
                                        onDayMouseUp={() =>
                                            setShowCalendar(false)
                                        }
                                    />
                                </Flex>
                            </Fade>

                            <Flex
                                width="100%"
                                justifyContent="space-between"
                                flexDirection={['column', 'column', 'row']}
                            >
                                <Flex
                                    width={['100%', '100%', '48%']}
                                    flexDirection="column"
                                >
                                    <Input
                                        name="contractor_name_1"
                                        label="Contratante 1"
                                        isRequired
                                    />
                                </Flex>

                                <Flex
                                    width={['100%', '100%', '48%']}
                                    flexDirection="column"
                                >
                                    <Input
                                        name="contractor_telephone_1"
                                        label="Telefone"
                                        mask="(99) 99999-9999"
                                        isRequired
                                    />
                                </Flex>
                            </Flex>

                            <Input
                                name="honored_name"
                                label="Nome do Homenageado"
                                isRequired
                            />
                        </Flex>

                        <Flex
                            width={['100%', '100%', '48%']}
                            flexDirection="column"
                        >
                            <Flex
                                width="100%"
                                justifyContent="space-between"
                                flexDirection={['column', 'column', 'row']}
                            >
                                <Flex
                                    width={['100%', '100%', '48%']}
                                    flexDirection="column"
                                >
                                    <Input
                                        name="num_payers"
                                        label="Nº de Pagantes"
                                        isRequired
                                    />
                                </Flex>

                                <Flex
                                    width={['100%', '100%', '48%']}
                                    flexDirection="column"
                                >
                                    <Select
                                        name="max_non_paying_age"
                                        label="Idade não pagantes"
                                        isRequired
                                    >
                                        <option value="0">Nenhum</option>
                                        <option value="1">Até 1 Ano</option>
                                        <option value="2">Até 2 Anos</option>
                                        <option value="3">Até 3 Anos</option>
                                        <option value="4">Até 4 Anos</option>
                                        <option value="5">Até 5 Anos</option>
                                        <option value="6">Até 6 Anos</option>
                                        <option value="7">Até 7 Anos</option>
                                        <option value="8">Até 8 Anos</option>
                                        <option value="9">Até 9 Anos</option>
                                        <option value="10">Até 10 Anos</option>
                                        <option value="11">Até 11 Anos</option>
                                        <option value="12">Até 12 Anos</option>
                                        <option value="13">Até 13 Anos</option>
                                        <option value="14">Até 14 Anos</option>
                                        <option value="15">Até 15 Anos</option>
                                        <option value="16">Até 16 Anos</option>
                                    </Select>
                                </Flex>
                            </Flex>

                            <Flex
                                width="100%"
                                justifyContent="space-between"
                                flexDirection={['column', 'column', 'row']}
                            >
                                <Flex
                                    width={['100%', '100%', '48%']}
                                    flexDirection="column"
                                >
                                    <Input
                                        name="start_hour"
                                        label="Hora de Início"
                                        type="time"
                                        isRequired
                                    />
                                </Flex>

                                <Flex
                                    width={['100%', '100%', '48%']}
                                    flexDirection="column"
                                >
                                    <Input
                                        name="end_hour"
                                        label="Hora do Fim"
                                        type="time"
                                        isRequired
                                    />
                                </Flex>
                            </Flex>

                            <Flex width="100%" justifyContent="space-between">
                                <Flex width="48%" flexDirection="column">
                                    <Input
                                        name="contractor_name_2"
                                        label="Contratante 2"
                                    />
                                </Flex>

                                <Flex width="48%" flexDirection="column">
                                    <Input
                                        name="contractor_telephone_2"
                                        label="Telefone"
                                        mask="(99) 99999-9999"
                                    />
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>

                    <TextArea
                        name="observations"
                        label="Observações sobre o evento"
                    />

                    <Flex
                        width="100%"
                        maxWidth={['100%', '100%', '400px']}
                        flexDirection="column"
                    >
                        <SelectMultiple
                            name="Receptionists"
                            label="Adicionar Recepcionistas"
                            isRequired
                            size="sm"
                            placeholder="Pesquisar Recepcionistas"
                            onSearch={handleSearchReceptionists}
                            onSetItems={handleSetReceptionists}
                            defaultValues={[]}
                            enterPress={false}
                        />

                        <Flex width="100%" flexDirection="column">
                            <Text fontWeight="600" fontSize="14px">
                                Recepcionistas designados(as)
                            </Text>

                            {selectedReceptionists.map(receptionist => (
                                <Flex
                                    key={receptionist.id}
                                    width="100%"
                                    whiteSpace="nowrap"
                                    fontSize="14px"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Text width="120px">
                                        {receptionist.name}
                                    </Text>

                                    <Text>{receptionist.whatsapp}</Text>

                                    <Icon
                                        as={FiTrash}
                                        color="red.500"
                                        title="Remover"
                                        cursor="pointer"
                                        onClick={() =>
                                            handleRemoveReceptionist(
                                                receptionist.id
                                            )
                                        }
                                    />
                                </Flex>
                            ))}
                        </Flex>

                        <Flex width="100%" flexDirection="column" mt="24px">
                            <Text fontWeight="600" fontSize="14px">
                                Pesquisa de opinião
                            </Text>

                            <Select name="search_id" isRequired>
                                {searches.map((search: any) => (
                                    <option key={search.id} value={search.id}>
                                        {search.name}
                                    </option>
                                ))}
                            </Select>
                        </Flex>
                    </Flex>

                    <Flex width="100%" justifyContent="center">
                        <Button
                            title="Cadastrar"
                            backgroundColor="green.500"
                            color="white"
                            width="160px"
                            borderRadius="4px"
                            py="8px"
                            onClick={() => handleSubmit()}
                        />
                    </Flex>
                </Flex>
            </Flex>
        </Form>
    );
};

export default EventRegisterPage;
