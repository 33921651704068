import React, { useCallback, useEffect, useState } from 'react';
import { BiChevronDown, BiChevronRight } from 'react-icons/bi';

import { Flex, Text, Icon, Stack } from '@chakra-ui/react';

import apiBackend from '../../../apis';
import { HTTP_RESPONSE } from '../../../constants';
import { useLayout } from '../../../hooks/layout';

const SelectUnity: React.FC = () => {
    const [isClicked, setIsClicked] = useState(false);

    const { user, currentUnity, setCurrentUnity } = useLayout();

    const [unities, setUnities] = useState<any[]>([]);

    const handleChangeUnity = useCallback((unity: any) => {
        setCurrentUnity(unity);
        setIsClicked(false);
    }, []);

    const handleClickMenu = useCallback(async () => {
        await loadUnities();
        setIsClicked(oldState => !oldState);
    }, []);

    const loadUnities = useCallback(async () => {
        await apiBackend(user?.token)
            .get(`/houses/${user?.user.house_id}/unities`)
            .then(response => {
                const { status, data } = response;

                if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                    setUnities(data);
                    setCurrentUnity(data[0]);
                }
            });
    }, [user]);

    useEffect(() => {
        loadUnities();
    }, []);

    return (
        <Flex
            width="100%"
            flexDirection="column"
            px="8px"
            mt="24px"
            fontSize="14px"
            position="relative"
        >
            <Text color="gray.100">Unidade Atual</Text>
            <Flex
                mt="8px"
                width="100%"
                height="40px"
                alignItems="center"
                px="8px"
                justifyContent="space-between"
                backgroundColor="gray.500"
                cursor="pointer"
                color="white"
                onClick={() => handleClickMenu()}
            >
                {!currentUnity ? 'Selecione uma unidade' : currentUnity.name}
                <Icon
                    as={!isClicked ? BiChevronRight : BiChevronDown}
                    fontSize="18px"
                />
            </Flex>

            {isClicked && (
                <Flex
                    width="calc(100% - 16px)"
                    height="auto"
                    position="absolute"
                    overflow="auto"
                    flexDirection="column"
                    backgroundColor="gray.500"
                    color="white"
                    top="80px"
                    boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                >
                    <Stack>
                        {unities.map(unity => (
                            <Flex
                                key={unity.id}
                                width="100%"
                                p="8px"
                                _hover={{
                                    backgroundColor: 'orange.500'
                                }}
                                cursor="pointer"
                                onClick={() => handleChangeUnity(unity)}
                            >
                                <Text>{unity.name}</Text>
                            </Flex>
                        ))}
                    </Stack>
                </Flex>
            )}
        </Flex>
    );
};

export default SelectUnity;
