import { format } from 'date-fns';
import React, { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { Flex, Box, Image, Stack, Text, useToast } from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import colorfulLogo from '../../../../assets/images/colorful-logo.png';
import apiBackend from '../../../../shared/apis';
import Button from '../../../../shared/components/Button';
import Form from '../../../../shared/components/Form';
import Input from '../../../../shared/components/Input';
import { HTTP_RESPONSE } from '../../../../shared/constants';
import { useLayout } from '../../../../shared/hooks/layout';

const LoginPage: React.FC = () => {
    const { setUser } = useLayout();

    const formRef = useRef<FormHandles>(null);

    const toast = useToast();
    const navigate = useNavigate();

    const handleSubmit = useCallback(async () => {
        const login = formRef.current?.getFieldValue('login');
        const password = formRef.current?.getFieldValue('password');

        const { status, data } = await apiBackend('/').post('/auth/user', {
            login: login,
            password: password
        });

        if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
            const { token, user, plan, ddd } = data;

            localStorage.clear();

            localStorage.setItem(
                `@recept:session:${format(new Date(), 'ddMMyyyy')}`,
                JSON.stringify({ token, user, plan })
            );

            localStorage.setItem('@recept:ddd', ddd);

            setUser({
                token,
                user,
                plan
            });

            navigate('/dashboard');
        } else {
            toast({
                title: 'Login Invalido',
                description: '',
                status: 'error',
                duration: 4000,
                isClosable: true
            });
        }
    }, []);

    useEffect(() => {
        const authData = localStorage.getItem(
            `@recept:session:${format(new Date(), 'ddMMyyyy')}`
        );

        if (authData) {
            const { token, user, plan } = JSON.parse(authData);

            setUser({
                token,
                user,
                plan
            });

            navigate('/dashboard');
        }
    }, []);

    return (
        <Flex
            width="100vw"
            height="100vh"
            backgroundColor="gray.900"
            justifyContent="center"
            alignItems="center"
            px={['8px', '16px', '24px']}
        >
            <Form ref={formRef} onSubmit={() => null}>
                <Flex
                    width="100%"
                    height="100%"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Flex
                        width="100%"
                        maxWidth="496px"
                        height="496px"
                        backgroundColor="white"
                        borderRadius="10px"
                        boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                        flexDirection="column"
                        alignItems="center"
                        px={['32px', '32px', '80px']}
                    >
                        <Box width="288px">
                            <Image src={colorfulLogo} />
                        </Box>

                        <Stack width="100%" flexDirection="column" spacing="4">
                            <Input name="login" label="Login" size="lg" />

                            <Input
                                name="password"
                                label="Senha"
                                size="lg"
                                type="password"
                            />
                        </Stack>

                        <Button
                            title="login"
                            backgroundColor="yellow.500"
                            mt="16px"
                            fontSize="24px"
                            py="8px"
                            onClick={() => handleSubmit()}
                        />

                        <Text
                            fontSize="12px"
                            cursor="pointer"
                            _hover={{
                                textDecoration: 'underline'
                            }}
                            my="16px"
                        >{`Esqueci minha senha >`}</Text>
                    </Flex>
                </Flex>
            </Form>
        </Flex>
    );
};

export default LoginPage;
