import axios from 'axios';
import React, { useCallback, useRef } from 'react';

import {
    Text,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Flex,
    Button,
    useToast
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import apiBackend from '../../../../../../shared/apis';
import Form from '../../../../../../shared/components/Form';
import Input from '../../../../../../shared/components/Input';
import Select from '../../../../../../shared/components/Select';
import { HTTP_RESPONSE } from '../../../../../../shared/constants';
import { useLayout } from '../../../../../../shared/hooks/layout';

interface IProps {
    isOpen: any;
    onClose: any;
    loadFunction: any;
    unity?: any;
}

const ModalUnity: React.FC<IProps> = ({
    isOpen,
    onClose,
    loadFunction,
    unity
}) => {
    const { user } = useLayout();

    const toast = useToast();

    const formRef = useRef<FormHandles>(null as any);

    const handleSearchCEP = useCallback(async () => {
        const cepRaw = formRef.current.getFieldValue('zipcode');
        const cep = cepRaw.replace(/\D/g, '');

        const { data } = await axios.get(
            `https://viacep.com.br/ws/${cep}/json`
        );

        if (!data.erro) {
            formRef.current.getFieldRef('city').value = data.localidade;

            formRef.current.getFieldRef('street').value = data.logradouro;
            formRef.current.getFieldRef('number').value = '';
            formRef.current.getFieldRef('complement').value = '';
            formRef.current.getFieldRef('uf').value = data.uf;
        }
    }, [formRef]);

    const handleSubmit = useCallback(() => {
        const data = formRef.current?.getData();

        if (!unity) {
            apiBackend(user?.token)
                .post(`/houses/${user.user.house_id}/unity`, data)
                .then(response => {
                    const { status } = response;

                    if (status === HTTP_RESPONSE.STATUS.CREATED) {
                        loadFunction();

                        onClose();

                        toast({
                            title: 'Unidade Criada',
                            description: '',
                            status: 'success',
                            duration: 4000,
                            isClosable: true
                        });
                    }
                });
        } else {
            apiBackend(user?.token)
                .put(`/houses/${user.user.house_id}/unities/${unity.id}`, data)
                .then(response => {
                    const { status } = response;

                    if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                        loadFunction();

                        onClose();

                        toast({
                            title: 'Unidade Atualizada',
                            description: '',
                            status: 'success',
                            duration: 4000,
                            isClosable: true
                        });
                    }
                });
        }
    }, [user, formRef, unity]);

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody pb="8px">
                        <Form ref={formRef}>
                            <Flex width="100%" flexDirection="column">
                                <Text fontSize="16px" fontWeight="500">
                                    {!unity ? 'Nova Unidade' : 'Editar Unidade'}
                                </Text>
                            </Flex>

                            <Flex
                                width={'100'}
                                flexDirection="column"
                                mt="24px"
                            >
                                <Input
                                    name="name"
                                    label="Nome da Unidade"
                                    defaultValue={unity?.name || ''}
                                />
                                <Input
                                    name="whatsapp"
                                    label="Whatsapp"
                                    defaultValue={unity?.whatsapp || ''}
                                />

                                <Flex
                                    width="100%"
                                    justifyContent="space-between"
                                >
                                    <Input
                                        width="48%"
                                        name="zipcode"
                                        label="CEP"
                                        onChange={() => handleSearchCEP()}
                                        defaultValue={unity?.zipcode || ''}
                                    />
                                    <Select
                                        name="uf"
                                        label="UF"
                                        width="48%"
                                        placeholder="Selecione Estado"
                                        defaultValue={unity?.uf || ''}
                                    >
                                        <option value="AC">Acre</option>
                                        <option value="AL">Alagoas</option>
                                        <option value="AP">Amapá</option>
                                        <option value="AM">Amazonas</option>
                                        <option value="BA">Bahia</option>
                                        <option value="CE">Ceará</option>
                                        <option value="DF">
                                            Distrito Federal
                                        </option>
                                        <option value="ES">
                                            Espírito Santo
                                        </option>
                                        <option value="GO">Goiás</option>
                                        <option value="MA">Maranhão</option>
                                        <option value="MT">Mato Grosso</option>
                                        <option value="MS">
                                            Mato Grosso do Sul
                                        </option>
                                        <option value="MG">Minas Gerais</option>
                                        <option value="PA">Pará</option>
                                        <option value="PB">Paraíba</option>
                                        <option value="PR">Paraná</option>
                                        <option value="PE">Pernambuco</option>
                                        <option value="PI">Piauí</option>
                                        <option value="RJ">
                                            Rio de Janeiro
                                        </option>
                                        <option value="RN">
                                            Rio Grande do Norte
                                        </option>
                                        <option value="RS">
                                            Rio Grande do Sul
                                        </option>
                                        <option value="RO">Rondônia</option>
                                        <option value="RR">Roraima</option>
                                        <option value="SC">
                                            Santa Catarina
                                        </option>
                                        <option value="SP">São Paulo</option>
                                        <option value="SE">Sergipe</option>
                                        <option value="TO">Tocantins</option>
                                        <option value="EX">Estrangeiro</option>
                                    </Select>
                                </Flex>

                                <Flex
                                    width="100%"
                                    justifyContent="space-between"
                                >
                                    <Input
                                        width="48%"
                                        name="city"
                                        label="Cidade"
                                        defaultValue={unity?.city || ''}
                                    />

                                    <Input
                                        name="number"
                                        label="Nº"
                                        width="48%"
                                        defaultValue={unity?.number || ''}
                                    />
                                </Flex>

                                <Input
                                    name="street"
                                    label="Rua"
                                    width="100%"
                                    defaultValue={unity?.street || ''}
                                />

                                <Input
                                    name="complement"
                                    label="Complemento"
                                    width="100%"
                                    defaultValue={unity?.complement || ''}
                                />
                            </Flex>

                            <Flex
                                width="100%"
                                flexDirection="row"
                                justifyContent="space-between"
                                mt="16px"
                                mb="8px"
                                fontWeight="500"
                            >
                                <Button
                                    width="48%"
                                    backgroundColor="green.500"
                                    onClick={() => handleSubmit()}
                                    color="white"
                                >
                                    {!unity ? 'Cadastrar' : 'Editar'}
                                </Button>
                                <Button
                                    width="48%"
                                    backgroundColor="red.500"
                                    color="white"
                                    onClick={() => onClose()}
                                >
                                    Cancelar
                                </Button>
                            </Flex>
                        </Form>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ModalUnity;
