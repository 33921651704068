import React, { useCallback, useRef, useState } from 'react';
import { FiTrash } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Flex,
    Icon,
    Stack,
    Text,
    useToast
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import apiBackend from '../../../../shared/apis';
import Button from '../../../../shared/components/Button';
import Checkbox from '../../../../shared/components/Checkbox';
import Form from '../../../../shared/components/Form';
import Input from '../../../../shared/components/Input';
import SelectMultiple from '../../../../shared/components/SelectMultiple';
import UploadPhoto from '../../../../shared/components/UploadPhoto';
import { HTTP_RESPONSE } from '../../../../shared/constants';
import { useLayout } from '../../../../shared/hooks/layout';

const UserRegisterPage: React.FC = () => {
    const formRef = useRef<FormHandles>();
    const { user } = useLayout();
    const toast = useToast();
    const navigate = useNavigate();

    const [unities, setUnities] = useState<any[]>([]);
    const [dataUri, setDataUri] = useState('');
    const [permissions, setPermissions] = useState<string[]>([]);

    const [selectedUnities, setSelectedUnities] = useState<any[]>([]);

    const handleSearchUnities = useCallback(async () => {
        const { status, data } = await apiBackend(user?.token).get(
            `/houses/${user?.user.house_id}/unities`
        );

        if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
            if (data) {
                setUnities(data);

                return data.map(data => ({
                    id: data.id,
                    text: data.name,
                    value: data.id
                }));
            }
        }

        return {};
    }, [user]);

    const handleSetUnity = useCallback(
        (ids: string[]): void => {
            const updatedselectedUnities = ids.map(id =>
                unities.find(item => item.id === id)
            );

            setSelectedUnities(updatedselectedUnities);
        },
        [unities]
    );

    const handleChangePermissions = useCallback(value => {
        setPermissions(oldState => {
            if (oldState.includes(value))
                return [...oldState.filter(item => item !== value)];
            else {
                return [...oldState, value];
            }
        });
    }, []);

    const handleRemoveUnity = useCallback((id: string) => {
        setSelectedUnities(oldState => {
            const updatedState = oldState.filter(item => item.id !== id);

            return [...updatedState];
        });
    }, []);

    const handleSubmit = useCallback(() => {
        const data = formRef.current?.getData();

        apiBackend(user?.token)
            .post('/users', {
                ...data,
                type: 'ADMIN',
                permissions,
                unities: selectedUnities,
                photo_url: dataUri
            })
            .then(response => {
                const { status, data } = response;

                if (status === HTTP_RESPONSE.STATUS.CREATED) {
                    toast({
                        title: 'Cadastro Realizado',
                        description: '',
                        status: 'success',
                        duration: 4000,
                        isClosable: true
                    });

                    navigate('/users');
                }

                if (status === HTTP_RESPONSE.STATUS.BAD_REQUEST) {
                    toast({
                        title: 'Atenção',
                        description: data.message,
                        status: 'warning',
                        duration: 4000,
                        isClosable: true
                    });
                }
            });
    }, [user, selectedUnities, dataUri, permissions]);

    return (
        <Form ref={formRef}>
            <Flex width="100%" flexDirection="column">
                <Breadcrumb
                    mb="16px"
                    fontWeight="500"
                    color="gray.900"
                    fontSize="14px"
                >
                    <BreadcrumbItem>
                        <BreadcrumbLink href="/dashboard">
                            Início
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem>
                        <BreadcrumbLink href="/users">Usuários</BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem isCurrentPage>
                        <BreadcrumbLink href="/users/new">
                            Novo Usuário
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>

                <Flex
                    width="100%"
                    flexDirection="column"
                    color="gray.900"
                    backgroundColor="white"
                    p="32px"
                >
                    <Text fontWeight="500" fontSize="14px" mb="13px">
                        Novo Usuário
                    </Text>

                    <Flex
                        width="100%"
                        justifyContent="space-between"
                        flexDirection={['column', 'column', 'row']}
                    >
                        <Flex
                            width={['100%', '100%', '48%']}
                            flexDirection="column"
                        >
                            <Flex
                                width="100%"
                                justifyContent="space-between"
                                flexDirection={['column', 'column', 'row']}
                                flexWrap="wrap"
                            >
                                <Flex
                                    width={['100%', '100%', '48%']}
                                    flexDirection="column"
                                >
                                    <Input name="name" label="Nome" />
                                </Flex>

                                <Flex
                                    width={['100%', '100%', '48%']}
                                    flexDirection="column"
                                >
                                    <Input name="email" label="Email" />
                                </Flex>

                                <Flex
                                    width={['100%', '100%', '48%']}
                                    flexDirection="column"
                                >
                                    <Input
                                        name="whatsapp"
                                        label="Whatsapp"
                                        mask="(99) 99999-9999"
                                    />
                                </Flex>
                            </Flex>

                            <Flex
                                width="100%"
                                justifyContent="space-between"
                                flexDirection={['column', 'column', 'row']}
                            >
                                <Flex
                                    width={['100%', '100%', '48%']}
                                    flexDirection="column"
                                >
                                    <Input name="login" label="Login" />
                                </Flex>

                                <Flex
                                    width={['100%', '100%', '48%']}
                                    flexDirection="column"
                                >
                                    <Input
                                        name="password"
                                        label="Senha"
                                        type="password"
                                    />
                                </Flex>
                            </Flex>
                        </Flex>

                        <Flex
                            width={['100%', '100%', '48%']}
                            flexDirection="column"
                            alignItems="center"
                        >
                            <UploadPhoto setFunction={setDataUri} />
                        </Flex>
                    </Flex>

                    <Flex width="100%" mt="8px">
                        <Stack
                            direction={['column', 'column', 'row']}
                            spacing={['2', '2', '8']}
                        >
                            <Checkbox
                                name="register_event"
                                isChecked={permissions.includes(
                                    'REGISTER_EVENT'
                                )}
                                onChange={() =>
                                    handleChangePermissions('REGISTER_EVENT')
                                }
                            >
                                <Text fontSize="14px">Cadastro de Eventos</Text>
                            </Checkbox>

                            <Checkbox
                                name="register_event"
                                isChecked={permissions.includes(
                                    'REGISTER_RECEPTIONIST'
                                )}
                                onChange={() =>
                                    handleChangePermissions(
                                        'REGISTER_RECEPTIONIST'
                                    )
                                }
                            >
                                <Text fontSize="14px">
                                    Cadastro de Recepcionista
                                </Text>
                            </Checkbox>

                            <Checkbox
                                name="register_event"
                                isChecked={permissions.includes(
                                    'REGISTER_INTERACTION'
                                )}
                                onChange={() =>
                                    handleChangePermissions(
                                        'REGISTER_INTERACTION'
                                    )
                                }
                            >
                                <Text fontSize="14px">Interações</Text>
                            </Checkbox>
                        </Stack>
                    </Flex>

                    <Flex
                        width="100%"
                        maxWidth={['100%', '100%', '400px']}
                        flexDirection="column"
                        mt="24px"
                    >
                        <SelectMultiple
                            name="unitys"
                            label="Adicionar Unidades"
                            size="sm"
                            onSearch={handleSearchUnities}
                            onSetItems={handleSetUnity}
                            defaultValues={[]}
                            enterPress={false}
                        />

                        <Flex width="100%" flexDirection="column">
                            <Text fontWeight="600" fontSize="14px">
                                Unidades designados(as)
                            </Text>

                            {selectedUnities.map(unity => (
                                <Flex
                                    key={unity.id}
                                    width="100%"
                                    whiteSpace="nowrap"
                                    fontSize="14px"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Text width="120px">{unity.name}</Text>

                                    <Icon
                                        as={FiTrash}
                                        color="red.500"
                                        title="Remover"
                                        cursor="pointer"
                                        onClick={() =>
                                            handleRemoveUnity(unity.id)
                                        }
                                    />
                                </Flex>
                            ))}
                        </Flex>
                    </Flex>

                    <Flex width="100%" justifyContent="center" mt="24px">
                        <Button
                            title="Cadastrar"
                            backgroundColor="green.500"
                            color="white"
                            width="160px"
                            borderRadius="4px"
                            py="8px"
                            onClick={() => handleSubmit()}
                        />
                    </Flex>
                </Flex>
            </Flex>
        </Form>
    );
};

export default UserRegisterPage;
