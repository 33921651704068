import React, { useCallback } from 'react';
import Dropzone from 'react-dropzone';
import { FiAlertTriangle, FiCamera, FiCheck } from 'react-icons/fi';

import { Flex, Box, Text } from '@chakra-ui/react';

import apiBackend from '../../apis';

interface IProps {
    setUploadedImage: any;
    width?: any;
    height?: any;
    url: string;
}

const FileUpload: React.FC<IProps> = ({
    setUploadedImage,
    width = '200px',
    height = '160px',
    url = ''
}) => {
    const handleOnUploadImage = useCallback(
        async (file: any) => {
            try {
                const image = {
                    key: Math.random().toString(36).substr(2, 9),
                    id: null,
                    file: file[0],
                    name: file[0].name,
                    preview: URL.createObjectURL(file[0]),
                    progress: 0,
                    uploaded: false,
                    error: false,
                    url: null,
                    is_cover: false
                };

                setUploadedImage(image);

                const data = new FormData();

                let response: any = null;

                data.append('file', image.file, image.name);

                response = await apiBackend('').post(url, data, {
                    onUploadProgress: (e: any) => {
                        const progress = parseInt(
                            Math.round((e.loaded * 100) / e.total).toString(),
                            10
                        );

                        setUploadedImage({
                            ...image,
                            progress
                        });
                    }
                });

                setUploadedImage({
                    ...image,
                    id: response.data[0],
                    uploaded: true,
                    url: response?.data?.url
                });
            } catch (error) {
                console.log('log', error);
                setUploadedImage(oldState => {
                    return {
                        ...oldState,
                        error: true
                    };
                });
            }
        },
        [setUploadedImage]
    );

    return (
        <Dropzone
            accept={{
                image: ['*']
            }}
            onDropAccepted={handleOnUploadImage}
        >
            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                <Flex
                    flexDirection="column"
                    alignItems="center"
                    mr="16px"
                    {...getRootProps()}
                    outline="none"
                >
                    <input {...getInputProps()} />
                    <Box
                        mt="10px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        border="2px dashed"
                        borderColor="gray.600"
                        width={width}
                        height={height}
                        _hover={{
                            cursor: 'pointer'
                        }}
                    >
                        {!isDragActive && !isDragReject && (
                            <>
                                <FiCamera size={40} />
                                <Text fontSize="12px" mt="16px">
                                    Arraste ou clique aqui
                                </Text>
                            </>
                        )}

                        {isDragActive && !isDragReject && (
                            <Flex
                                flexDirection="column"
                                color="green.500"
                                alignItems="center"
                            >
                                <FiCheck size={40} />
                                <Text fontSize="12px" mt="16px">
                                    Solte o arquivo aqui
                                </Text>
                            </Flex>
                        )}

                        {isDragReject && (
                            <Flex
                                flexDirection="column"
                                color="pink.500"
                                alignItems="center"
                            >
                                <FiAlertTriangle size={40} />
                                <Text fontSize="12px" mt="16px">
                                    Arquivo não suportado
                                </Text>
                            </Flex>
                        )}
                    </Box>
                </Flex>
            )}
        </Dropzone>
    );
};

export default FileUpload;
