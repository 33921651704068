import React, { useEffect, useState } from 'react';

import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Flex,
    Text,
    useDisclosure
} from '@chakra-ui/react';

import apiBackend from '../../shared/apis';
import Button from '../../shared/components/Button';
import ModalDeleteConfirmation from '../../shared/components/ModalDeleteConfirmation';
import { HTTP_RESPONSE } from '../../shared/constants';
import { useLayout } from '../../shared/hooks/layout';

const PlansIndex: React.FC = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [showChangePlan, setShowChangePlan] = useState(false);
    const [plans, setPlans] = useState([]);
    const { user } = useLayout();

    useEffect(() => {
        apiBackend('')
            .get('/plans')
            .then(response => {
                const { status, data } = response;

                if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                    setPlans(data);
                }
            });
    }, []);

    return (
        <Flex width="100%" flexDirection="column">
            <Breadcrumb
                mb="16px"
                fontWeight="500"
                color="gray.900"
                fontSize="14px"
            >
                <BreadcrumbItem>
                    <BreadcrumbLink href="/dashboard">Início</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink href="/plans">Planos</BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>

            {showChangePlan && (
                <Flex
                    width="100%"
                    backgroundColor="yellow.100"
                    border="2px solid"
                    color="gray.600"
                    borderColor="yellow.500"
                    justifyContent="center"
                    fontWeight="500"
                    mb="8px"
                    py="8px"
                    px="8px"
                    fontSize={['12px', '14px']}
                    cursor="pointer"
                >
                    <Text>
                        Estamos verificando sua solicitação de upgrade,
                        entraremos em contato.
                    </Text>
                </Flex>
            )}

            <Flex
                width="100%"
                flexDirection="column"
                color="gray.900"
                backgroundColor="white"
                p="32px"
            >
                <Text fontWeight="500" fontSize="14px" mb="13px">
                    Planos
                </Text>

                <Flex width="100%" justifyContent="space-between">
                    {plans.map((item: any) => (
                        <Flex
                            width="400px"
                            height="480px"
                            flexDirection="column"
                            alignItems="center"
                            border="2px solid"
                            borderColor={
                                user.plan.id !== item.id
                                    ? 'yellow.500'
                                    : 'green.500'
                            }
                            px="24px"
                            py="16px"
                            backgroundColor="gray.100"
                        >
                            <Text
                                color="yellow.500"
                                fontSize="18PX"
                                fontWeight="600"
                                mb="40px"
                            >
                                {item.name}
                            </Text>

                            <Flex
                                fontSize="14px"
                                fontWeight="500"
                                dangerouslySetInnerHTML={{
                                    __html: `
                                Eventos Por Mês: ${item.num_events}<br>
                                Mensagem de saudação por whatsapp customizada: Sim<br>
                                Personalização da mensagem de saudação: ${
                                    item.greeting_message ? 'Sim' : 'Não'
                                }<br>
                                Envio Pesquisa de satisfação por whatsapp<br>
                                Perguntas na pesquisa de satisfação: ${Number(
                                    item.num_questions
                                )}<br>
                                Personalização da pergunta na pesquisa: ${
                                    item.custom_question ? 'Sim' : 'Não'
                                }<br>
                                Número de recepcionistas: ${
                                    item.num_receptionists === '-1'
                                        ? 'Ilimitado'
                                        : Number(item.num_receptionists)
                                }<br>
                                Numero próprio de whatsapp + atendimento: ${
                                    item.custom_number ? 'Sim' : 'Não'
                                }<br> 
                                Modulo Marketing / atendimento (Envio de campanhas por whatsapp): ${
                                    item.marketing_module ? 'Sim' : 'Não'
                                }`
                                }}
                            />

                            {user.plan.id !== item.id && (
                                <Button
                                    mt="auto"
                                    height="32px"
                                    title="Migrar para este plano"
                                    backgroundColor="green.500"
                                    color="white"
                                    borderRadius="2px"
                                    fontWeight="500"
                                    fontSize="12px"
                                    onClick={() => onOpen()}
                                />
                            )}
                        </Flex>
                    ))}
                </Flex>
            </Flex>

            <ModalDeleteConfirmation
                title="Solicitação de upgrade de plano?"
                deleteFunction={() => setShowChangePlan(true)}
                isOpen={isOpen}
                onClose={onClose}
            />
        </Flex>
    );
};

export default PlansIndex;
