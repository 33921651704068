import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiEdit, FiTrash2 } from 'react-icons/fi';

import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Flex,
    Icon,
    Stack,
    Text,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import apiBackend from '../../../../shared/apis';
import Button from '../../../../shared/components/Button';
import Form from '../../../../shared/components/Form';
import Input from '../../../../shared/components/Input';
import ModalDeleteConfirmation from '../../../../shared/components/ModalDeleteConfirmation';
import UploadPhoto from '../../../../shared/components/UploadPhoto';
import { HTTP_RESPONSE } from '../../../../shared/constants';
import { useLayout } from '../../../../shared/hooks/layout';
import ModalUnity from './components/ModalUnity.ts';

const GeneralSettingsPage: React.FC = () => {
    const { user } = useLayout();

    const formRef = useRef<FormHandles>(null);
    const toast = useToast();
    const [dataUri, setDataUri] = useState('');

    const [houseEvent, setHouseEvent] = useState(null as any);

    const [unities, setUnities] = useState<any[]>([]);
    const [selectedItem, setSelectedItem] = useState('');

    const [currentEditUnity, setCurrentEditUnity] = useState(null as any);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        isOpen: isOpen2,
        onOpen: onOpen2,
        onClose: onClose2
    } = useDisclosure();

    const handleDelete = useCallback(async (id: string) => {
        setSelectedItem(id);
        onOpen();
    }, []);

    const deleteFunction = useCallback(() => {
        apiBackend(user?.token)
            .put(`/houses/${user.user.house_id}/unities/${selectedItem}/delete`)
            .then(response => {
                const { status } = response;

                if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                    setUnities(oldState =>
                        oldState.filter(item => item.id !== selectedItem)
                    );

                    toast({
                        title: 'Unidade Removida',
                        description: '',
                        status: 'success',
                        duration: 4000,
                        isClosable: true
                    });
                }
            });
    }, [user, selectedItem]);

    const handleSubmit = useCallback(() => {
        const data = formRef.current?.getData();

        if (data && data.ddd) {
            localStorage.setItem('@recept:ddd', data.ddd);
        }

        apiBackend(user?.token)
            .put(`/houses/${user.user.house_id}`, {
                ...data,
                logo_url: dataUri
            })
            .then(response => {
                const { status } = response;

                if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                    toast({
                        title: 'Configurações Atualizada',
                        description: '',
                        status: 'success',
                        duration: 4000,
                        isClosable: true
                    });
                }
            });
    }, [user, formRef, dataUri]);

    const handleEditUnity = useCallback(unity => {
        setCurrentEditUnity(unity);
        onOpen2();
    }, []);

    const loadUnities = useCallback(() => {
        apiBackend(user?.token)
            .get(`/houses/${user?.user.house_id}/unities`)
            .then(response => {
                const { status, data } = response;

                if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                    setUnities(data);
                }
            });

        setCurrentEditUnity(null);
    }, [user]);

    useEffect(() => {
        if (user) {
            apiBackend(user?.token)
                .get(`/houses/${user?.user.house_id}`)
                .then(response => {
                    const { status, data } = response;

                    if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                        if (data && data.ddd) {
                            localStorage.setItem('@recept:ddd', data.ddd);
                        }

                        setHouseEvent(data);

                        if (data.logo_url) {
                            setDataUri(data.logo_url);
                        }
                    }
                });
        }

        loadUnities();
    }, [user, loadUnities]);

    return (
        houseEvent && (
            <Form ref={formRef}>
                <Flex width="100%" flexDirection="column">
                    <Breadcrumb
                        mb="16px"
                        fontWeight="500"
                        color="gray.900"
                        fontSize="14px"
                    >
                        <BreadcrumbItem>
                            <BreadcrumbLink href="/dashboard">
                                Início
                            </BreadcrumbLink>
                        </BreadcrumbItem>

                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbItem>
                                <BreadcrumbLink href="/settings">
                                    Configurações
                                </BreadcrumbLink>
                            </BreadcrumbItem>
                        </BreadcrumbItem>
                    </Breadcrumb>

                    <Flex
                        width="100%"
                        flexDirection="column"
                        color="gray.900"
                        backgroundColor="white"
                        p="32px"
                    >
                        <Text fontWeight="500" fontSize="16px" mb="13px">
                            Configurações Gerais
                        </Text>

                        <Flex
                            width="100%"
                            justifyContent="space-between"
                            flexDirection={['column', 'column', 'row']}
                        >
                            <Flex width={['48%']} flexDirection="column">
                                <Input
                                    name="name"
                                    label="Nome da Casa"
                                    defaultValue={houseEvent.name}
                                />
                                <Input
                                    name="whatsapp"
                                    label="Whatsapp"
                                    defaultValue={houseEvent.whatsapp}
                                />
                                <Input
                                    name="email"
                                    label="E-mail"
                                    defaultValue={houseEvent.email}
                                />

                                <Input
                                    name="ddd"
                                    label="DDD Padrão"
                                    defaultValue={houseEvent.ddd}
                                    type="number"
                                />
                            </Flex>

                            <Flex
                                width={['100%', '100%', '48%']}
                                flexDirection="column"
                                alignItems="center"
                            >
                                <UploadPhoto
                                    setFunction={setDataUri}
                                    defaultImage={dataUri}
                                />
                            </Flex>
                        </Flex>

                        <Flex width="100%" justifyContent="center" mt="24px">
                            <Button
                                title="Salvar"
                                backgroundColor="green.500"
                                color="white"
                                width="160px"
                                borderRadius="4px"
                                py="8px"
                                onClick={() => handleSubmit()}
                            />
                        </Flex>
                    </Flex>

                    <Flex
                        width="100%"
                        flexDirection="column"
                        color="gray.900"
                        backgroundColor="white"
                        p="32px"
                        mt="24px"
                    >
                        <Flex
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Text fontWeight="500" fontSize="16px" mb="13px">
                                Unidades
                            </Text>

                            <Button
                                title="Adicionar Unidade"
                                backgroundColor="green.500"
                                color="white"
                                width="160px"
                                borderRadius="4px"
                                py="4px"
                                fontSize="14px"
                                onClick={() => {
                                    setCurrentEditUnity(null);
                                    onOpen2();
                                }}
                                mb="16px"
                            />
                        </Flex>

                        <Flex
                            width="100%"
                            flexDirection="column"
                            overflow="auto"
                        >
                            <Flex
                                width="auto"
                                minWidth="100%"
                                backgroundColor="gray.300"
                                px="8px"
                            >
                                <Flex
                                    width="100%"
                                    minWidth="160px"
                                    fontSize="14px"
                                    fontWeight="600"
                                >
                                    <Text>Nome</Text>
                                </Flex>

                                <Flex
                                    width="100%"
                                    minWidth="160px"
                                    fontSize="14px"
                                    fontWeight="600"
                                >
                                    <Text>Cidade</Text>
                                </Flex>

                                <Flex
                                    width="100%"
                                    minWidth="160px"
                                    fontSize="14px"
                                    fontWeight="600"
                                >
                                    <Text>Endereço</Text>
                                </Flex>

                                <Flex
                                    width="100%"
                                    minWidth="160px"
                                    fontSize="14px"
                                    fontWeight="600"
                                >
                                    <Text>Whats</Text>
                                </Flex>

                                <Flex
                                    width="160px"
                                    fontSize="14px"
                                    fontWeight="600"
                                >
                                    <Text>Ação</Text>
                                </Flex>
                            </Flex>

                            {unities.map((unity: any) => (
                                <Flex
                                    key={unity.id}
                                    width="100%"
                                    flexDirection="column"
                                    fontSize="14px"
                                    px="8px"
                                >
                                    <Flex width="100%" py="8px">
                                        <Flex width="100%" minWidth="160px">
                                            <Text>{unity.name}</Text>
                                        </Flex>

                                        <Flex width="100%" minWidth="160px">
                                            <Text
                                                color="green.500"
                                                fontWeight="600"
                                            >
                                                {unity.city}
                                            </Text>
                                        </Flex>

                                        <Flex width="100%" minWidth="160px">
                                            <Text>{unity.address}</Text>
                                        </Flex>

                                        <Flex width="100%" minWidth="160px">
                                            <Text>{unity.whatsapp}</Text>
                                        </Flex>

                                        <Flex width="160px">
                                            <Stack direction="row">
                                                <Flex
                                                    width="24px"
                                                    height="24px"
                                                    backgroundColor="gray.900"
                                                    color="white"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    title="Editar"
                                                    cursor="pointer"
                                                    borderRadius="50%"
                                                    onClick={() =>
                                                        handleEditUnity(unity)
                                                    }
                                                >
                                                    <Icon
                                                        as={FiEdit}
                                                        fontSize="18px"
                                                        color="white"
                                                    />
                                                </Flex>

                                                <Flex
                                                    width="24px"
                                                    height="24px"
                                                    backgroundColor="red.500"
                                                    color="white"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    title="Excluir"
                                                    cursor="pointer"
                                                    borderRadius="50%"
                                                    onClick={() =>
                                                        handleDelete(unity.id)
                                                    }
                                                >
                                                    <Icon
                                                        as={FiTrash2}
                                                        fontSize="18px"
                                                    />
                                                </Flex>
                                            </Stack>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            ))}
                        </Flex>
                    </Flex>
                    <ModalDeleteConfirmation
                        deleteFunction={deleteFunction}
                        isOpen={isOpen}
                        onClose={onClose}
                    />

                    <ModalUnity
                        isOpen={isOpen2}
                        onClose={onClose2}
                        loadFunction={loadUnities}
                        unity={currentEditUnity}
                    />
                </Flex>
            </Form>
        )
    );
};

export default GeneralSettingsPage;
