import { useMemo } from 'react';

import { Flex, Text } from '@chakra-ui/react';

export const MaxCapacity = ({ event }: any): any => {
    const maxCapicity = useMemo(() => {
        if (!event) return;
        const percent = Number(event.num_payers) / Number(event.num_guests);
        if (percent >= 0.9) return true;
        return false;
    }, [event]);

    return (
        maxCapicity && (
            <Flex
                width="100%"
                flexDirection="column"
                backgroundColor="red.100"
                color="red.500"
                fontWeight="600"
                px="24px"
                py="8px"
                mb="24px"
            >
                <Text>{`90% dos convidados pagantes, atingido.`}</Text>
            </Flex>
        )
    );
};
