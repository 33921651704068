import React, { useEffect, useState } from 'react';
import { FiAlertTriangle } from 'react-icons/fi';

import { Flex, Icon } from '@chakra-ui/react';

import Input from '../../../../../shared/components/Input';
import Select from '../../../../../shared/components/Select';

// import { Container } from './styles';

interface IProps {
    index: number;
    isAdult?: boolean;
}

const GuestData: React.FC<IProps> = ({ index, isAdult = true }) => {
    const [invalidNumber, setInvalidNumber] = useState(true);

    const [value, setValue] = useState('');

    useEffect(() => {
        const defaultDDD = localStorage.getItem('@recept:ddd');

        if (isAdult && defaultDDD) {
            setValue(`(${defaultDDD})`);
        }
    }, []);

    return (
        <Flex
            width="100%"
            flexDirection="column"
            backgroundColor={isAdult ? 'gray.100' : '#E3FBE8'}
            p="16px"
            mb="8px"
        >
            <Input
                color={isAdult ? 'green.500' : 'red.500'}
                width="400px"
                isRequired
                name={
                    isAdult ? `adults[${index - 1}]` : `childrens[${index - 1}]`
                }
                label={`${
                    isAdult ? `Adulto ${index + 1}` : `Criança ${index}`
                }`}
                mb="8px"
                backgroundColor={'white'}
                fontSize="16px"
                size="md"
            />
            <Flex width="100%" maxWidth="400px" position="relative">
                {isAdult && (
                    <Input
                        width="400px"
                        name={
                            isAdult
                                ? `telephones[${index - 1}]`
                                : `ages[${index - 1}]`
                        }
                        label={`${isAdult ? 'Telefone' : 'Idade'}`}
                        isRequired
                        mb="8px"
                        backgroundColor={'white'}
                        fontSize="16px"
                        type="tel"
                        size="md"
                        mask={isAdult ? '(99) 99999-9999' : undefined}
                        value={value}
                        onChange={e => {
                            setValue(e.currentTarget.value);
                            if (isAdult) {
                                const regex = new RegExp(
                                    /\(\d{2,}\) \d{4,}-\d{4}/
                                );

                                if (!regex.test(e.currentTarget.value)) {
                                    setInvalidNumber(true);
                                } else {
                                    setInvalidNumber(false);
                                }
                            }
                        }}
                    />
                )}

                {!isAdult && (
                    <Select
                        width="400px"
                        name={`ages[${index - 1}]`}
                        label={'Idade'}
                        isRequired
                        mb="8px"
                        backgroundColor={'white'}
                        fontSize="16px"
                        size="md"
                        value={value}
                        onChange={e => {
                            setValue(e.currentTarget.value);
                        }}
                    >
                        <option value="1">1 Ano</option>
                        <option value="2">2 Anos</option>
                        <option value="3">3 Anos</option>
                        <option value="4">4 Anos</option>
                        <option value="5">5 Anos</option>
                        <option value="6">6 Anos</option>
                        <option value="7">7 Anos</option>
                        <option value="8">8 Anos</option>
                        <option value="9">9 Anos</option>
                        <option value="10">10 Anos</option>
                        <option value="11">11 Anos</option>
                        <option value="12">12 Anos</option>
                        <option value="13">13 Anos</option>
                        <option value="14">14 Anos</option>
                    </Select>
                )}

                {isAdult && invalidNumber && (
                    <Icon
                        as={FiAlertTriangle}
                        title="Numero não é valido"
                        position="absolute"
                        right="10px"
                        top="38px"
                        color="yellow.500"
                    />
                )}
            </Flex>
        </Flex>
    );
};

export default GuestData;
