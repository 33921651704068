import React, { useState } from 'react';
import { FiMenu } from 'react-icons/fi';

import { Flex, Stack, Icon, Text } from '@chakra-ui/react';

import { API_BASE_URL } from '../../constants';
import { useLayout } from '../../hooks/layout';
import AvatarUpload from '../AvatarUpload';

const Header: React.FC = () => {
    const { showMenu, setShowMenu } = useLayout();

    const { user } = useLayout();

    const [, setPhoto] = useState(null);

    return (
        <Flex
            width="100%"
            height="56px"
            justifyContent="center"
            alignItems="center"
            color="blue.800"
            backgroundColor="white"
            boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
            px="24px"
        >
            <Icon
                as={FiMenu}
                color="orange.500"
                fontSize="24px"
                display={['block', 'block', 'block', 'none']}
                onClick={() => setShowMenu(!showMenu)}
            />

            <Stack direction="row" alignItems="center" ml="auto" spacing="16px">
                <Text>{`Olá, ${user?.user?.name}`}</Text>

                <Flex>
                    <AvatarUpload
                        setUploadedImage={setPhoto}
                        url={`${API_BASE_URL}/users/${user?.user.id}/avatar`}
                        photo={user?.user?.photo?.url}
                    />
                </Flex>
            </Stack>
        </Flex>
    );
};

export default Header;
