import { addDays, format } from 'date-fns';
import React, { useEffect, useState } from 'react';

import { Document, Page, View, Text, Image } from '@react-pdf/renderer';

const PDFDocument: React.FC<any> = ({ event, guests, logo }) => {
    const [data, setData] = useState({
        numGuests: 0,
        payers: 0,
        nonPayers: 0
    });

    useEffect(() => {
        const nonPayersGuests = guests.filter(
            item =>
                item.age <= event.max_non_paying_age &&
                item.is_children === true
        );

        setData({
            numGuests: guests.length,
            payers: guests.length - nonPayersGuests.length,
            nonPayers: nonPayersGuests.length
        });
    }, []);

    return event ? (
        <Document>
            <Page
                size="A4"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingHorizontal: '8px',
                    color: '#4B535E'
                }}
            >
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: logo ? 'space-between' : 'flex-end'
                    }}
                >
                    {logo && (
                        <Image
                            src={logo}
                            style={{
                                marginTop: '8px',
                                width: '64px'
                            }}
                        />
                    )}

                    <Image
                        src={'/colorful-logo.png'}
                        style={{
                            width: '96px'
                        }}
                    />
                </View>
                <View
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        fontSize: '14px',
                        marginVertical: '16px',
                        fontWeight: 'bold',
                        justifyContent: 'center'
                    }}
                >
                    <Text>{`Lista de Convidados - ${event.name}`}</Text>
                </View>
                <View
                    style={{
                        fontSize: '10px',
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                >
                    <Text
                        style={{
                            fontWeight: 'bold',
                            marginRight: '8px'
                        }}
                    >
                        Data:
                    </Text>
                    <Text
                        style={{
                            marginRight: '8px'
                        }}
                    >
                        {event.date
                            ? format(
                                  addDays(new Date(event.date), 1),
                                  'dd/MM/yy'
                              )
                            : ''}
                    </Text>
                    <Text
                        style={{
                            fontWeight: 'bold',
                            marginRight: '8px'
                        }}
                    >
                        |
                    </Text>
                    <Text
                        style={{
                            fontWeight: 'bold',
                            marginRight: '8px'
                        }}
                    >
                        Início:
                    </Text>
                    <Text
                        style={{
                            marginRight: '8px'
                        }}
                    >
                        {event.start_hour}
                    </Text>
                    <Text
                        style={{
                            fontWeight: 'bold',
                            marginRight: '8px'
                        }}
                    >
                        |
                    </Text>
                    <Text
                        style={{
                            fontWeight: 'bold',
                            marginRight: '8px'
                        }}
                    >
                        Pagantes:
                    </Text>
                    <Text
                        style={{
                            marginRight: '8px'
                        }}
                    >
                        {`> ${event.max_non_paying_age}`}
                    </Text>
                    <Text
                        style={{
                            fontWeight: 'bold',
                            marginRight: '8px'
                        }}
                    >
                        |
                    </Text>
                    <Text
                        style={{
                            fontWeight: 'bold',
                            marginRight: '8px'
                        }}
                    >
                        Contratado:
                    </Text>
                    <Text>{event.num_guests}</Text>
                </View>

                <View
                    style={{
                        fontSize: '10px',
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                >
                    <Text
                        style={{
                            fontWeight: 'bold',
                            marginRight: '8px'
                        }}
                    >
                        Contratante(s):
                    </Text>

                    <Text>
                        {`${event.contractor_name_1}${
                            event.contractor_name_2
                                ? ` - ${event.contractor_name_2}`
                                : ''
                        }`}
                    </Text>
                </View>

                <View
                    style={{
                        fontSize: '10px',
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                >
                    <View
                        style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <Text
                            style={{
                                fontWeight: 'bold',
                                marginRight: '8px',
                                color: '#FFAB06'
                            }}
                        >
                            {`${data.numGuests} Presentes:`}
                        </Text>
                        <Text
                            style={{
                                fontWeight: 'bold',
                                marginRight: '8px',
                                color: '#FFAB06'
                            }}
                        >
                            {`${Number(
                                data.numGuests / event.num_guests
                            ).toFixed(2)}%`}
                        </Text>
                    </View>
                    <Text
                        style={{
                            fontWeight: 'bold',
                            marginRight: '8px'
                        }}
                    >
                        |
                    </Text>

                    <View
                        style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <Text
                            style={{
                                fontWeight: 'bold',
                                marginRight: '8px',
                                color: '#FFAB06'
                            }}
                        >
                            {`${data.payers} Pagantes:`}
                        </Text>
                        <Text
                            style={{
                                fontWeight: 'bold',
                                marginRight: '8px',
                                color: '#FFAB06'
                            }}
                        >
                            {`${Number(data.payers / event.num_guests).toFixed(
                                2
                            )}%`}
                        </Text>
                    </View>
                    <Text
                        style={{
                            fontWeight: 'bold',
                            marginRight: '8px'
                        }}
                    >
                        |
                    </Text>

                    <View
                        style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <Text
                            style={{
                                fontWeight: 'bold',
                                marginRight: '8px',
                                color: '#FFAB06'
                            }}
                        >
                            {`${data.nonPayers} Não Pagantes:`}
                        </Text>
                        <Text
                            style={{
                                fontWeight: 'bold',
                                marginRight: '8px',
                                color: '#FFAB06'
                            }}
                        >
                            {`${Number(
                                data.nonPayers / event.num_guests
                            ).toFixed(2)}%`}
                        </Text>
                    </View>
                </View>
                <View
                    style={{
                        marginTop: '24px',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        minWidth: '100px',
                        paddingHorizontal: '8px',
                        fontSize: '10px',
                        fontWeight: 'bold',
                        borderColor: '',
                        padding: '6px 8px'
                    }}
                >
                    <View
                        style={{
                            width: '5%',
                            textAlign: 'center'
                        }}
                    >
                        <Text>Seq</Text>
                    </View>
                    <View
                        style={{
                            width: '30%',
                            textAlign: 'center'
                        }}
                    >
                        <Text>Nome do Convidado(a)</Text>
                    </View>

                    <View
                        style={{
                            width: '30%',
                            textAlign: 'center'
                        }}
                    >
                        <Text>Perfil</Text>
                    </View>

                    <View
                        style={{
                            width: '15%',
                            textAlign: 'center'
                        }}
                    >
                        <Text>Hora de Chegada</Text>
                    </View>

                    <View
                        style={{
                            width: '20%',
                            textAlign: 'center'
                        }}
                    >
                        <Text>Whatsapp</Text>
                    </View>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        fontSize: '10px',
                        fontWeight: 'normal'
                    }}
                >
                    {guests.map((people: any, index: number) => (
                        <View
                            key={people.id}
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                backgroundColor: people.is_adult_main
                                    ? '#efefef'
                                    : '#fff',
                                borderColor: '#e2e8f0',
                                borderBottom: '1px solid',
                                borderBottomColor: '#C9C6C6'
                            }}
                        >
                            <View
                                style={{
                                    width: '5%',
                                    textAlign: 'center',
                                    border: '1px solid #C9C6C6'
                                }}
                            >
                                <Text>{index}</Text>
                            </View>
                            <View
                                style={{
                                    width: '30%',
                                    borderColor: '#C9C6C6',
                                    padding: '0px 8px',
                                    border: '1px solid #C9C6C6'
                                }}
                            >
                                <Text
                                    style={{
                                        marginLeft: !people.is_adult_main
                                            ? '16px'
                                            : '0px'
                                    }}
                                >
                                    {people.name}
                                </Text>
                            </View>

                            <View
                                style={{
                                    width: '30%',
                                    borderColor: '#C9C6C6',
                                    padding: '0px 8px',
                                    border: '1px solid #C9C6C6'
                                }}
                            >
                                <Text>
                                    {people.is_children
                                        ? `${
                                              people.age <=
                                              event.max_non_paying_age
                                                  ? `Não Pagante`
                                                  : 'Pagante'
                                          } (${people.age} anos)`
                                        : 'Pagante'}
                                </Text>
                            </View>
                            <View
                                style={{
                                    width: '15%',
                                    textAlign: 'center',
                                    border: '1px solid #C9C6C6'
                                }}
                            >
                                <Text>
                                    {format(
                                        new Date(people.created_at),
                                        'HH:mm'
                                    )}
                                </Text>
                            </View>

                            <View
                                style={{
                                    width: '20%',
                                    textAlign: 'center',
                                    border: '1px solid #C9C6C6'
                                }}
                            >
                                <Text>
                                    {people.telephone
                                        ? `${String(people.telephone).substring(
                                              0,
                                              3
                                          )}*** **${String(
                                              people.telephone
                                          ).substring(9, 15)}`
                                        : ''}
                                </Text>
                            </View>
                        </View>
                    ))}
                </View>
            </Page>
        </Document>
    ) : (
        <></>
    );
};

export default PDFDocument;
