import { addDays, format } from 'date-fns';
import { differenceInSeconds } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { FcExport } from 'react-icons/fc';
import { useNavigate, useParams } from 'react-router-dom';

import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Divider,
    Flex,
    Icon,
    Text,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import { PDFDownloadLink } from '@react-pdf/renderer';

import apiBackend from '../../../../shared/apis';
import Button from '../../../../shared/components/Button';
import Form from '../../../../shared/components/Form';
import Input from '../../../../shared/components/Input';
import { MaxCapacity } from '../../../../shared/components/MaxCapacity';
import ModalDeleteConfirmation from '../../../../shared/components/ModalDeleteConfirmation';
import { HTTP_RESPONSE } from '../../../../shared/constants';
import { useLayout } from '../../../../shared/hooks/layout';
import TextUtils from '../../../../shared/utils/TextUtils';
import ModalFinish from './components/ModalFinish';
import PDFDocument from './components/PDFDocument';

const EventViewPage: React.FC = () => {
    const { user } = useLayout();

    const { id } = useParams();

    const [event, setEvent] = useState(null as any);
    const [logo, setLogo] = useState(null as any);

    const [missingSeconds, setMissingSeconds] = useState(0);

    const minutesAmount = Math.floor(missingSeconds / 60);
    const secondsAmount = Math.floor(missingSeconds % 60);

    const minutes = minutesAmount;
    const seconds = secondsAmount % 60;

    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        isOpen: isOpen1,
        onOpen: onOpen1,
        onClose: onClose1
    } = useDisclosure();

    const {
        isOpen: isOpen2,
        onOpen: onOpen2,
        onClose: onClose2
    } = useDisclosure();

    const navigate = useNavigate();
    const toast = useToast();

    const [defaultGuests, setDefaultGuests] = useState<any>([]);
    const [guests, setGuests] = useState<any>([]);

    const handleStartEvent = useCallback(async () => {
        onOpen();
    }, []);

    const startEvent = useCallback(() => {
        apiBackend(user?.token)
            .put(`/events/${id}/status`, {
                status: 'STARTED'
            })
            .then(response => {
                const { status } = response;

                if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                    toast({
                        title: 'Evento Iniciado',
                        description: '',
                        status: 'success',
                        duration: 4000,
                        isClosable: true
                    });
                }
            });

        history.go(0);
    }, [user, id]);

    const handleAddGuest = useCallback(() => {
        if (event.status === 'WAITING_START') {
            onOpen2();
        }

        if (event.status === 'STARTED') {
            navigate(`/events/${event.id}/guests`);
        }

        if (event.status === 'FINISHED') {
            onOpen1();
        }
    }, [event]);

    const endEvent = useCallback(() => {
        apiBackend(user?.token)
            .put(`/events/${id}/status`, {
                status: 'FINISHED'
            })
            .then(response => {
                const { status } = response;

                if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                    toast({
                        title: 'Evento Encerrado',
                        description: '',
                        status: 'success',
                        duration: 4000,
                        isClosable: true
                    });
                }
            });

        history.go(0);
    }, [user, id]);

    const loadData = useCallback(() => {
        if (user) {
            apiBackend('')
                .get(`/houses/${user?.user.house_id}`)
                .then(response => {
                    const { status, data } = response;

                    if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                        if (data.logo_url) {
                            setLogo(data.logo_url);
                        }
                    }
                });
        }

        apiBackend(user?.token)
            .get(`/events/${id}`)
            .then(response => {
                const { status, data } = response;

                if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                    setEvent(data);

                    const dataGuests: any[] = [];

                    data.families = data.families.map((family: any) =>
                        family.peoples.sort(
                            (a: any, b: any) =>
                                b.is_adult_main - a.is_adult_main
                        )
                    );

                    data.families.map((family: any) => {
                        family.map((people: any) => {
                            dataGuests.push(people);
                        });
                    });

                    setDefaultGuests(dataGuests);
                    setGuests(dataGuests);

                    setInterval(async () => {
                        let targetDate: any = null;

                        if (data.status === 'WAITING_START') {
                            const startDate = addDays(new Date(data.date), 1);
                            const [hour, min] = data.start_hour.split(':');
                            startDate.setHours(Number(hour), Number(min), 0, 0);

                            targetDate = startDate;
                        }

                        if (data.status === 'STARTED') {
                            const endDate = addDays(new Date(data.date), 1);
                            const [hour, min] = data.end_hour.split(':');
                            endDate.setHours(Number(hour), Number(min), 0, 0);

                            targetDate = endDate;
                        }

                        if (data.status !== 'FINISHED') {
                            const now = new Date().getTime();

                            if (differenceInSeconds(targetDate, now) <= 0) {
                                const { data } = await apiBackend(
                                    user?.token
                                ).get(`/events/${id}`);

                                setEvent(data);
                            }
                        }
                    }, 1000);
                }
            });
    }, [user, id, endEvent]);

    const handleSearchGuests = useCallback(
        (value: string) => {
            if (value.length > 0) {
                const regex = new RegExp(value, 'i');
                const filtredGuests = defaultGuests.filter((people: any) =>
                    people.name.match(regex)
                );

                setGuests(filtredGuests);
            } else {
                setGuests(defaultGuests);
            }
        },
        [defaultGuests]
    );

    useEffect(() => {
        loadData();
    }, [loadData]);

    useEffect(() => {
        let interval: any;

        if (event && event.status === 'STARTED') {
            const endDate = addDays(new Date(event.date), 1);
            const [hour, min] = event.end_hour.split(':');
            endDate.setHours(Number(hour), Number(min), 0, 0);

            setMissingSeconds(differenceInSeconds(endDate, new Date()));

            interval = setInterval(() => {
                const endDate = addDays(new Date(event.date), 1);
                const [hour, min] = event.end_hour.split(':');
                endDate.setHours(Number(hour), Number(min), 0, 0);

                setMissingSeconds(differenceInSeconds(endDate, new Date()));
            }, 1000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [event]);

    return (
        event && (
            <Flex width="100%" flexDirection="column">
                <Flex width="100%" justifyContent="space-between">
                    <Breadcrumb
                        mb="16px"
                        fontWeight="500"
                        color="gray.900"
                        fontSize="12px"
                    >
                        <BreadcrumbItem>
                            <BreadcrumbLink href="/dashboard">
                                Início
                            </BreadcrumbLink>
                        </BreadcrumbItem>

                        <BreadcrumbItem>
                            <BreadcrumbLink
                                href={
                                    user?.user?.type === 'RECEPTIONIST'
                                        ? '/dashboard'
                                        : '/events'
                                }
                            >
                                Eventos
                            </BreadcrumbLink>
                        </BreadcrumbItem>

                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>{event.name}</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                    <Flex
                        display={['flex', 'flex', 'none']}
                        fontSize="14px"
                        fontWeight="600"
                        color="green.500"
                        onClick={() =>
                            navigate(
                                user?.user?.type === 'RECEPTIONIST'
                                    ? '/dashboard'
                                    : `/events`
                            )
                        }
                    >
                        <Text>{`Voltar>`}</Text>
                    </Flex>
                </Flex>

                {missingSeconds > 0 && (
                    <Flex
                        width="100%"
                        flexDirection="column"
                        backgroundColor="yellow.100"
                        color="red.500"
                        fontWeight="600"
                        px="24px"
                        py="8px"
                        mb="24px"
                    >
                        <Text>{`[${minutes}m:${seconds}s] para encerrar o evento.`}</Text>
                    </Flex>
                )}

                <MaxCapacity event={event} />

                <Flex
                    width="100%"
                    flexDirection="column"
                    backgroundColor="white"
                    px="24px"
                    py="8px"
                >
                    <Flex
                        width="100%"
                        fontSize="14px"
                        justifyContent="space-between"
                        flexDirection={['column', 'column', 'row']}
                    >
                        <Flex
                            width={['100%', '100%', '48%']}
                            flexDirection="column"
                            fontSize="14px"
                            order={[2, 2, 1]}
                        >
                            <Flex width="100%" fontWeight="600">
                                <Text fontWeight="500" mr="4px">
                                    Status:
                                </Text>
                                <Text
                                    color={TextUtils.convertEventStatusColor(
                                        event.status
                                    )}
                                >
                                    {TextUtils.convertEventStatus(event.status)}
                                </Text>
                            </Flex>
                            <Flex width="100%">
                                <Text fontWeight="500" mr="4px">
                                    Nome:
                                </Text>
                                <Text>{event.name}</Text>
                            </Flex>
                            <Flex width="100%">
                                <Text fontWeight="500" mr="4px">
                                    Contratante:
                                </Text>
                                <Text>{event.contractor_name_1}</Text>
                            </Flex>
                            <Flex width="100%">
                                <Text fontWeight="500" mr="4px">
                                    Tipo de Festa:
                                </Text>
                                <Text>{event.type}</Text>
                            </Flex>
                            <Flex width="100%">
                                <Text fontWeight="500" mr="4px">
                                    Data:
                                </Text>
                                <Text>
                                    {format(
                                        addDays(new Date(event.date), 1),
                                        'dd/MM/yy'
                                    )}
                                </Text>
                            </Flex>
                            <Flex width="100%">
                                <Text fontWeight="500" mr="4px">
                                    Hora de Início:
                                </Text>
                                <Text>{event.start_hour}</Text>
                            </Flex>
                            <Flex width="100%">
                                <Text fontWeight="500" mr="4px">
                                    Hora do Fim:
                                </Text>
                                <Text>{event.end_hour}</Text>
                            </Flex>
                            <Flex width="100%">
                                <Text fontWeight="500" mr="4px">
                                    Nome do Homenageado:
                                </Text>
                                <Text>{event.honored_name}</Text>
                            </Flex>
                            <Flex width="100%">
                                <Text fontWeight="500" mr="4px">
                                    OBS:
                                </Text>
                                <Text>{event.observations}</Text>
                            </Flex>
                        </Flex>

                        <Flex
                            width={['100%', '100%', '48%']}
                            fontSize="14px"
                            justifyContent={['center', 'center', 'flex-end']}
                            order={[1, 1, 2]}
                        >
                            {event.status !== 'STARTED' &&
                                event.status !== 'FINISHED' && (
                                    <Button
                                        ml="16px"
                                        title="Iniciar Evento >"
                                        backgroundColor="transparent"
                                        color="green.500"
                                        width="160px"
                                        height="40px"
                                        borderRadius="4px"
                                        py="8px"
                                        fontSize="18px"
                                        onClick={() => handleStartEvent()}
                                    />
                                )}

                            {event.status === 'STARTED' && (
                                <Button
                                    ml="16px"
                                    title="Encerrar Evento"
                                    backgroundColor="red.500"
                                    color="white"
                                    width="120px"
                                    height="40px"
                                    borderRadius="4px"
                                    py="8px"
                                    fontSize="14px"
                                    onClick={() => handleStartEvent()}
                                />
                            )}
                        </Flex>
                    </Flex>

                    <Form>
                        <Flex width="100%" flexDirection="column" mt="24px">
                            <Flex
                                width="100%"
                                justifyContent="space-between"
                                alignItems="center"
                                mb={['16px', '16px', '8px']}
                                flexDirection={['column', 'column', 'row']}
                            >
                                <Flex
                                    alignItems="center"
                                    order={[3, 3, 0]}
                                    backgroundColor="gray.100"
                                    mt={['16px', '16px', '0px']}
                                    px="8px"
                                >
                                    <Text
                                        fontWeight="500"
                                        color="green.500"
                                        fontSize="12px"
                                        letterSpacing={'0.8px'}
                                    >
                                        CONVIDADOS NA CASA
                                    </Text>
                                </Flex>

                                <Button
                                    ml="16px"
                                    title="Adicionar Convidado"
                                    backgroundColor="green.500"
                                    color="white"
                                    width={['100%', '100%', '400px']}
                                    my={['8px', '8px', '0px']}
                                    borderRadius="4px"
                                    py="8px"
                                    fontSize="14px"
                                    onClick={() => handleAddGuest()}
                                />

                                <Input
                                    name="search"
                                    maxWidth="400px"
                                    placeholder="Pesquisar pelo nome"
                                    mb="0px"
                                    onChange={(e: any) =>
                                        handleSearchGuests(
                                            e.currentTarget.value
                                        )
                                    }
                                />
                            </Flex>

                            <Flex
                                id="guest-list"
                                width="100%"
                                overflow="auto"
                                flexDirection="column"
                            >
                                <Flex
                                    width={[
                                        'max-content',
                                        'max-content',
                                        '100%'
                                    ]}
                                    alignItems="center"
                                    backgroundColor="gray.300"
                                    whiteSpace="nowrap"
                                    px="8px"
                                >
                                    <Flex
                                        width="100%"
                                        minWidth="200px"
                                        fontSize="14px"
                                        fontWeight="600"
                                    >
                                        <Text>Nome</Text>
                                    </Flex>
                                    <Flex
                                        width="100%"
                                        minWidth="128px"
                                        fontSize="14px"
                                        fontWeight="600"
                                    >
                                        <Text>Whatsapp</Text>
                                    </Flex>
                                    <Flex
                                        width="100%"
                                        minWidth="100px"
                                        fontSize="14px"
                                        fontWeight="600"
                                    >
                                        <Text>Tipo</Text>
                                    </Flex>
                                    <Flex
                                        width="100%"
                                        minWidth="100px"
                                        fontSize="14px"
                                        fontWeight="600"
                                    >
                                        <Text>Hora de Chegada</Text>
                                    </Flex>
                                </Flex>

                                <Flex width="100%" flexDirection="column">
                                    {guests.map((people: any) => (
                                        <Flex
                                            key={people.id}
                                            width="100%"
                                            py="4px"
                                            px="8px"
                                            backgroundColor={
                                                people.is_adult_main
                                                    ? 'gray.100'
                                                    : 'white'
                                            }
                                            borderBottom="1px solid"
                                            borderColor="gray.200"
                                        >
                                            <Flex
                                                width="100%"
                                                minWidth="200px"
                                                fontSize="14px"
                                                ml={
                                                    !people.is_adult_main
                                                        ? '24px'
                                                        : '0px'
                                                }
                                            >
                                                <Text>{people.name}</Text>
                                            </Flex>
                                            <Flex
                                                width="100%"
                                                minWidth="128px"
                                                fontSize="14px"
                                            >
                                                <Text>{people.telephone}</Text>
                                            </Flex>
                                            <Flex
                                                width="100%"
                                                minWidth="100px"
                                                fontSize="14px"
                                            >
                                                <Text>
                                                    {people.is_children
                                                        ? 'Criança'
                                                        : 'Adulto'}
                                                </Text>
                                            </Flex>
                                            <Flex
                                                width="100%"
                                                minWidth="100px"
                                                fontSize="14px"
                                            >
                                                <Text>
                                                    {format(
                                                        new Date(
                                                            people.created_at
                                                        ),
                                                        'HH:mm'
                                                    )}
                                                </Text>
                                            </Flex>
                                        </Flex>
                                    ))}
                                </Flex>
                            </Flex>

                            <Divider my="16px" />

                            {guests.length > 0 && (
                                <PDFDownloadLink
                                    document={
                                        <PDFDocument
                                            event={event}
                                            guests={guests}
                                            logo={logo}
                                        />
                                    }
                                    fileName="lista_convidados.pdf"
                                >
                                    {() => (
                                        <Flex
                                            width="100%"
                                            justifyContent="flex-end"
                                            fontWeight="600"
                                            fontSize="14px"
                                            alignItems="center"
                                            cursor="pointer"
                                        >
                                            <Text mr="8px">Exportar</Text>
                                            <Icon
                                                as={FcExport}
                                                fontSize="18px"
                                            />
                                        </Flex>
                                    )}
                                </PDFDownloadLink>
                            )}
                        </Flex>
                    </Form>

                    {event.status !== 'STARTED' &&
                        event.status !== 'FINISHED' && (
                            <ModalDeleteConfirmation
                                deleteFunction={() => startEvent()}
                                isOpen={isOpen}
                                onClose={onClose}
                                title="Deseja Iniciar Evento?"
                            />
                        )}

                    {event.status === 'STARTED' && (
                        <ModalDeleteConfirmation
                            deleteFunction={() => endEvent()}
                            isOpen={isOpen}
                            onClose={onClose}
                            title="Deseja Encerrar Evento?"
                        />
                    )}

                    <ModalFinish isOpen={isOpen1} onClose={onClose1} />
                    <ModalFinish
                        isOpen={isOpen2}
                        onClose={onClose2}
                        title="Evento não Iniciado"
                    />
                </Flex>
            </Flex>
        )
    );
};

export default EventViewPage;
