import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Flex,
    Text,
    useToast
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import apiBackend from '../../../../shared/apis';
import Button from '../../../../shared/components/Button';
import Form from '../../../../shared/components/Form';
import Input from '../../../../shared/components/Input';
import UploadPhoto from '../../../../shared/components/UploadPhoto';
import { HTTP_RESPONSE } from '../../../../shared/constants';
import { useLayout } from '../../../../shared/hooks/layout';
import TextUtils from '../../../../shared/utils/TextUtils';

const ReceptionistEditPage: React.FC = () => {
    const formRef = useRef<FormHandles>(null);
    const { id } = useParams();

    const { user } = useLayout();
    const [receptionist, setReceptionist] = useState(null as any);
    const toast = useToast();
    const navigate = useNavigate();
    const [dataUri, setDataUri] = useState('');

    const handleSubmit = useCallback(() => {
        const data = formRef.current?.getData();

        apiBackend(user?.token)
            .put(`/users/${id}`, {
                ...data,
                email: '',
                login: TextUtils.removeCharacters(data?.whatsapp || ''),
                type: 'RECEPTIONIST',
                photo_url: dataUri
            })
            .then(response => {
                const { status, data } = response;

                if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                    toast({
                        title: 'Registro Atualizado',
                        description: '',
                        status: 'success',
                        duration: 4000,
                        isClosable: true
                    });

                    navigate('/receptionists');
                }

                if (status === HTTP_RESPONSE.STATUS.BAD_REQUEST) {
                    toast({
                        title: 'Ocorreu um erro',
                        description: data.message,
                        status: 'warning',
                        duration: 4000,
                        isClosable: true
                    });
                }
            });
    }, [formRef, user, dataUri, id]);

    useEffect(() => {
        apiBackend(user?.token)
            .get(`/users/${id}`)
            .then(response => {
                const { data } = response;

                setReceptionist(data);

                if (data.photo) {
                    setDataUri(data.photo.url);
                }
            });
    }, [user, id]);

    return (
        <Form ref={formRef}>
            <Flex width="100%" flexDirection="column">
                <Breadcrumb
                    mb="16px"
                    fontWeight="500"
                    color="gray.900"
                    fontSize="14px"
                >
                    <BreadcrumbItem>
                        <BreadcrumbLink href="/dashboard">
                            Início
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem>
                        <BreadcrumbLink href="/receptionists">
                            Recepcionista
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem isCurrentPage>
                        <BreadcrumbLink href="/Receptionists/new">
                            Atualizar Recepcionista
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>

                <Flex
                    width="100%"
                    flexDirection="column"
                    color="gray.900"
                    backgroundColor="white"
                    p="32px"
                >
                    <Text fontWeight="500" fontSize="14px" mb="13px">
                        Atualizar Recepcionista
                    </Text>

                    {receptionist && (
                        <Flex
                            width="100%"
                            justifyContent="space-between"
                            flexDirection={['column', 'column', 'row']}
                        >
                            <Flex
                                width={['100%', '100%', '48%']}
                                flexDirection="column"
                            >
                                <Flex
                                    width="100%"
                                    justifyContent="space-between"
                                    flexDirection={['column', 'column', 'row']}
                                >
                                    <Flex
                                        width={['100%', '100%', '48%']}
                                        flexDirection="column"
                                    >
                                        <Input
                                            name="name"
                                            label="Nome"
                                            defaultValue={receptionist.name}
                                        />
                                    </Flex>
                                </Flex>

                                <Flex
                                    width="100%"
                                    justifyContent="space-between"
                                    flexDirection={['column', 'column', 'row']}
                                >
                                    <Flex
                                        width={['100%', '100%', '48%']}
                                        flexDirection="column"
                                    >
                                        <Input
                                            name="whatsapp"
                                            label="Whatsapp/Login"
                                            defaultValue={receptionist.whatsapp}
                                        />
                                    </Flex>

                                    <Flex
                                        width={['100%', '100%', '48%']}
                                        flexDirection="column"
                                    >
                                        <Input
                                            name="password"
                                            label="Senha"
                                            type="password"
                                        />
                                    </Flex>
                                </Flex>
                            </Flex>

                            <Flex
                                width={['100%', '100%', '48%']}
                                flexDirection="column"
                                alignItems="center"
                            >
                                <UploadPhoto
                                    setFunction={setDataUri}
                                    defaultImage={dataUri}
                                />
                            </Flex>
                        </Flex>
                    )}

                    <Flex width="100%" justifyContent="center" mt="24px">
                        <Button
                            title="Atualizar"
                            backgroundColor="green.500"
                            color="white"
                            width="160px"
                            borderRadius="4px"
                            py="8px"
                            onClick={() => handleSubmit()}
                        />
                    </Flex>
                </Flex>
            </Flex>
        </Form>
    );
};

export default ReceptionistEditPage;
