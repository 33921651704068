import React, { useEffect } from 'react';

import { Flex, Image, Text } from '@chakra-ui/react';

import successFull from '../../../../../assets/images/successful.gif';

const Successful: React.FC = () => {
    useEffect(() => {
        setTimeout(() => history.go(0), 2000);
    }, []);

    return (
        <Flex
            flexDirection="column"
            position="fixed"
            width="100vw"
            height="100vh"
            top="0"
            left="0"
            justifyContent="center"
            alignItems="center"
            backgroundColor="#e1f4e5"
            onClick={() => history.go(0)}
        >
            <Image
                width="400px"
                src={successFull}
                onLoad={() => history.go(0)}
            />
            <Text color="#32d554" fontWeight="600" fontSize="24px">
                Concluido!
            </Text>
        </Flex>
    );
};

export default Successful;
