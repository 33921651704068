import React, { useEffect, useRef } from 'react';
import InputMask from 'react-input-mask';

import { Flex, Text, Input as ChakraInput, InputProps } from '@chakra-ui/react';
import { useField } from '@unform/core';

interface IProps extends InputProps {
    name: string;
    label?: string;
    mask?: string;
    formatChars?: {
        [key: string]: string;
    };
    maskChar?: string;
    isRequired?: boolean;
}

const Input: React.FC<IProps> = ({
    name,
    width = '100%',
    maxWidth = '100%',
    mask,
    formatChars,
    maskChar,
    label,
    isRequired,
    ...rest
}) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const { fieldName, error, registerField } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
            setValue(ref: any, value: string) {
                ref.setInputValue(value);
            },
            clearValue(ref: any) {
                ref.setInputValue('');
            }
        });
    }, [error, fieldName, registerField]);

    return (
        <Flex width={width} maxWidth={maxWidth} flexDirection="column">
            {label && (
                <Flex
                    width="100%"
                    textAlign="center"
                    mb="4px"
                    fontSize="14px"
                    justifyContent="space-between"
                >
                    <Flex>
                        <Text>{label}</Text>
                        {isRequired && (
                            <Text ml="8px" color="red.500">
                                *
                            </Text>
                        )}
                    </Flex>
                </Flex>
            )}

            <InputMask
                formatChars={formatChars}
                mask={mask}
                value={rest.value}
                onChange={rest.onChange}
                onBlur={rest.onBlur}
                maskChar={maskChar}
                type={rest.type}
                alwaysShowMask={false}
            >
                {(inputProps: any) => (
                    <ChakraInput
                        ref={inputRef}
                        name={name}
                        borderRadius="4px"
                        borderColor="gray.100"
                        focusBorderColor="gray.100"
                        size="sm"
                        mb="16px"
                        {...rest}
                        {...inputProps}
                    />
                )}
            </InputMask>
        </Flex>
    );
};

export default Input;
