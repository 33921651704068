import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiSave, FiTrash } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Flex,
    Icon,
    Stack,
    Text,
    useToast
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import apiBackend from '../../../../shared/apis';
import Button from '../../../../shared/components/Button';
import Form from '../../../../shared/components/Form';
import Input from '../../../../shared/components/Input';
import Select from '../../../../shared/components/Select';
import { HTTP_RESPONSE } from '../../../../shared/constants';
import { useLayout } from '../../../../shared/hooks/layout';

const SearchRegisterPage: React.FC = () => {
    const formRef = useRef<FormHandles>();
    const { user } = useLayout();
    const toast = useToast();
    const navigate = useNavigate();

    const [opnionLength, setOpnionsLength] = useState<number>(0);

    const [questions, setQuestions] = useState<any[]>([]);

    const handleAddQuestion = useCallback(() => {
        const question = formRef.current?.getFieldValue('question');
        const questionInput = formRef.current?.getFieldRef('question');

        if (!question) {
            toast({
                title: 'Questão Inválida',
                description: 'Preencha o campo corretamente',
                status: 'error',
                duration: 4000,
                isClosable: true
            });
            return;
        }

        questionInput.value = '';
        const newValue = String(question).trim();
        let exists = false;
        setQuestions(oldState => {
            if (oldState.includes(newValue)) {
                exists = true;
                return oldState;
            }
            return [...oldState, newValue];
        });

        if (exists)
            toast({
                title: 'Questão Inválida',
                description: 'Essa questão já foi adicionada',
                status: 'error',
                duration: 4000,
                isClosable: true
            });
    }, []);

    const handleRemoveQuestion = useCallback((question: string) => {
        setQuestions(oldState => {
            const updatedState = oldState.filter(item => item !== question);

            return [...updatedState];
        });
    }, []);

    const handleSubmit = useCallback(() => {
        const data = formRef.current?.getData();

        apiBackend(user?.token)
            .post('/searches', {
                ...data,
                questions: questions
            })
            .then(response => {
                const { status } = response;

                if (status === HTTP_RESPONSE.STATUS.CREATED) {
                    toast({
                        title: 'Cadastro Realizado',
                        description: '',
                        status: 'success',
                        duration: 4000,
                        isClosable: true
                    });

                    navigate('/searches');
                } else {
                    toast({
                        title: 'Ocorreu um erro',
                        description: '',
                        status: 'error',
                        duration: 4000,
                        isClosable: true
                    });
                }
            });
    }, [user, questions]);

    useEffect(() => {
        apiBackend(user?.token)
            .get(`/searches`)
            .then(response => {
                const { status, data } = response;

                if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                    setOpnionsLength(data.length);
                }
            });
    }, []);

    return (
        <Form ref={formRef}>
            <Flex width="100%" flexDirection="column">
                <Breadcrumb
                    mb="16px"
                    fontWeight="500"
                    color="gray.900"
                    fontSize="14px"
                >
                    <BreadcrumbItem>
                        <BreadcrumbLink href="/dashboard">
                            Início
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem>
                        <BreadcrumbLink href="/searches">
                            Pesquisa de Opnião
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem isCurrentPage>
                        <BreadcrumbLink href="/searches/new">
                            Novo
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>

                {user.plan.num_questions !== '-1' &&
                    opnionLength >= Number(user.plan.num_questions) && (
                        <Flex
                            width="100%"
                            backgroundColor="yellow.500"
                            justifyContent="center"
                            fontWeight="500"
                            mb="8px"
                            py="8px"
                            px="8px"
                            onClick={() => navigate('/plans')}
                            fontSize={['12px', '14px']}
                            cursor="pointer"
                        >
                            <Text>
                                Você excedeu o número máximo de recepcionistas.
                                Clique aqui para fazer upgrade.
                            </Text>
                        </Flex>
                    )}

                <Flex
                    width="100%"
                    flexDirection="column"
                    color="gray.900"
                    backgroundColor="white"
                    p="32px"
                >
                    <Stack
                        width="100%"
                        direction={['column', 'column', 'row']}
                        spacing={['0px', '0px', '24px']}
                    >
                        <Input
                            name="name"
                            label="Nome do Modelo"
                            maxWidth="300px"
                        />

                        <Select
                            label="Regra de Disparo"
                            name="trigger_rule"
                            maxWidth="300px"
                        >
                            <option value="1">
                                1 hora após o encerramento
                            </option>
                            <option value="2">
                                2 horas após o encerramento
                            </option>
                            <option value="4">
                                4 horas após o encerramento
                            </option>
                            <option value="8">
                                8 horas após o encerramento
                            </option>
                            <option value="12">
                                12 horas após o encerramento
                            </option>
                            <option value="16">
                                16 horas após o encerramento
                            </option>
                            <option value="24">
                                24 horas após o encerramento
                            </option>
                        </Select>
                    </Stack>

                    <Flex width="100%" alignItems="center">
                        <Input
                            name="question"
                            label="Nova Questão"
                            maxWidth="300px"
                        />

                        <Flex
                            mt="4px"
                            width="32px"
                            height="32px"
                            backgroundColor="green.500"
                            color="white"
                            alignItems="center"
                            ml="16px"
                            cursor="pointer"
                            onClick={() => handleAddQuestion()}
                        >
                            <Icon as={FiSave} ml="8px" title="Adicionar" />
                        </Flex>
                    </Flex>

                    {questions.map((item, index) => (
                        <Flex key={item} width="100%" alignItems="center">
                            <Icon
                                as={FiTrash}
                                color="red.500"
                                onClick={() => handleRemoveQuestion(item)}
                                mr="8px"
                            />

                            <Text fontSize="14px">{`${
                                index + 1
                            }. ${item}`}</Text>
                        </Flex>
                    ))}

                    {(user.plan.num_questions === '-1' ||
                        opnionLength < Number(user.plan.num_questions)) && (
                        <Flex width="100%" mt="24px">
                            <Button
                                title="Salvar"
                                backgroundColor="green.500"
                                color="white"
                                width="160px"
                                borderRadius="4px"
                                py="8px"
                                onClick={() => handleSubmit()}
                            />
                        </Flex>
                    )}
                </Flex>
            </Flex>
        </Form>
    );
};

export default SearchRegisterPage;
