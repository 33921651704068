import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiEdit } from 'react-icons/fi';

import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Divider,
    Flex,
    Icon,
    Text,
    useToast
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import apiBackend from '../../../shared/apis';
import Button from '../../../shared/components/Button';
import Form from '../../../shared/components/Form';
import Input from '../../../shared/components/Input';
import Select from '../../../shared/components/Select';
import TextArea from '../../../shared/components/TextArea';
import { HTTP_RESPONSE } from '../../../shared/constants';
import { useLayout } from '../../../shared/hooks/layout';
import TextUtils from '../../../shared/utils/TextUtils';

const WhatsappSettingsPage: React.FC = () => {
    const [rule, setRule] = useState(null as any);
    const [settings, setSettings] = useState([] as any);
    const { user } = useLayout();
    const formRef = useRef<FormHandles>(null);
    const toast = useToast();

    const handleSubmit = useCallback(() => {
        const data = formRef.current?.getData();

        if (rule) {
            apiBackend(user?.token)
                .put(`/settings/${rule.id}`, {
                    ...data
                })
                .then(response => {
                    const { status } = response;

                    if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                        toast({
                            title: 'Atualização Realizada',
                            description: '',
                            status: 'success',
                            duration: 4000,
                            isClosable: true
                        });

                        history.go(0);
                    } else {
                        toast({
                            title: 'Ocorreu um erro',
                            description: '',
                            status: 'error',
                            duration: 4000,
                            isClosable: true
                        });
                    }
                });
        }
    }, [formRef, rule]);

    useEffect(() => {
        apiBackend(user?.token)
            .get('/settings')
            .then(response => {
                const { status, data } = response;

                if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                    setSettings(data);
                }
            });
    }, [user]);

    return (
        <Form ref={formRef}>
            <Flex width="100%" flexDirection="column">
                <Breadcrumb
                    mb="16px"
                    fontWeight="500"
                    color="gray.900"
                    fontSize="14px"
                >
                    <BreadcrumbItem>
                        <BreadcrumbLink href="/dashboard">
                            Início
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem isCurrentPage>
                        <BreadcrumbItem>
                            <BreadcrumbLink href="/Receptionists">
                                Whatsapp
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                    </BreadcrumbItem>
                </Breadcrumb>

                <Flex
                    width="100%"
                    flexDirection="column"
                    color="gray.900"
                    backgroundColor="white"
                    p="32px"
                >
                    {rule && (
                        <Flex width="100%" flexDirection="column">
                            <Text fontWeight="500" fontSize="16px" mb="13px">
                                Regras de Disparo
                            </Text>

                            <Flex
                                width="100%"
                                justifyContent="space-between"
                                flexDirection={['column', 'column', 'row']}
                            >
                                <Flex width={['100%', '100%', '48%']}>
                                    <Input
                                        name="name"
                                        label="Nome da Regra"
                                        value={rule?.name}
                                        onChange={e =>
                                            setRule({
                                                ...rule,
                                                name: e.currentTarget.value
                                            })
                                        }
                                    />
                                </Flex>
                                <Flex width={['100%', '100%', '48%']}>
                                    <Select
                                        name="trigger"
                                        label="Quando Enviar"
                                        value={rule?.trigger}
                                        onChange={e =>
                                            setRule({
                                                ...rule,
                                                trigger: e.currentTarget.value
                                            })
                                        }
                                    >
                                        <option value="-1">Na chegada</option>
                                        <option value="-30">
                                            30 minutos após chegada
                                        </option>
                                        <option value="-60">
                                            1 hora após chegada
                                        </option>
                                        <option value="1">
                                            No fim do evento
                                        </option>
                                        <option value="30">
                                            30 minutos após fim do evento
                                        </option>
                                        <option value="60">
                                            1 hora após fim do evento
                                        </option>
                                    </Select>
                                </Flex>
                            </Flex>

                            <TextArea
                                name="message"
                                label="Mensagem"
                                value={rule?.message}
                                onChange={e =>
                                    setRule({
                                        ...rule,
                                        message: e.currentTarget.value
                                    })
                                }
                            />

                            <Flex width={['100%', '100%', '48%']}>
                                <Select
                                    name="status"
                                    label="Status"
                                    defaultValue={rule?.status}
                                >
                                    <option value="ENABLED">Ativo</option>
                                    <option value="DISABLED">Inativo</option>
                                </Select>
                            </Flex>

                            <Flex
                                width="100%"
                                justifyContent="center"
                                mt="24px"
                            >
                                <Button
                                    title="Confirmar"
                                    backgroundColor="green.500"
                                    color="white"
                                    width="160px"
                                    borderRadius="4px"
                                    py="8px"
                                    onClick={() => handleSubmit()}
                                />
                            </Flex>

                            <Divider my="24px" />
                        </Flex>
                    )}

                    <Flex width="100%" flexDirection="column" overflow="auto">
                        <Flex width="100%" backgroundColor="gray.300" px="8px">
                            <Flex width="100%" fontSize="14px" fontWeight="600">
                                <Text>Nome</Text>
                            </Flex>

                            <Flex
                                width="160px"
                                fontSize="14px"
                                fontWeight="600"
                            >
                                <Text>Status</Text>
                            </Flex>

                            <Flex
                                width="160px"
                                fontSize="14px"
                                fontWeight="600"
                            >
                                <Text>Ação</Text>
                            </Flex>
                        </Flex>
                        <Flex
                            width="100%"
                            flexDirection="column"
                            fontSize="14px"
                            px="8px"
                        >
                            {settings.map(setting => (
                                <Flex key={setting.id} width="100%" py="8px">
                                    <Flex width="100%">
                                        <Text>{setting.name}</Text>
                                    </Flex>

                                    <Flex width="100%" maxWidth="120px">
                                        <Text
                                            color={TextUtils.convertStatusColor(
                                                setting.status
                                            )}
                                            fontWeight="600"
                                        >
                                            {TextUtils.convertStatus(
                                                setting.status
                                            )}
                                        </Text>
                                    </Flex>

                                    <Flex width="160px">
                                        <Flex
                                            width="24px"
                                            height="24px"
                                            backgroundColor="gray.900"
                                            color="white"
                                            justifyContent="center"
                                            alignItems="center"
                                            title="Excluir"
                                            cursor="pointer"
                                            borderRadius="50%"
                                            onClick={() => setRule(setting)}
                                        >
                                            <Icon as={FiEdit} fontSize="18px" />
                                        </Flex>
                                    </Flex>
                                </Flex>
                            ))}
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Form>
    );
};

export default WhatsappSettingsPage;
