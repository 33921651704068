import React, { useCallback } from 'react';
import {
    FiCalendar,
    FiCommand,
    FiLogOut,
    FiMeh,
    FiMessageCircle,
    FiUser,
    FiUserPlus,
    FiUsers,
    FiX
} from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';

import { Flex, Box, Image, Icon, Text, Stack } from '@chakra-ui/react';

import logoWhite from '../../../assets/images/white-logo.png';
import apiBackend from '../../apis';
import { useLayout } from '../../hooks/layout';
import SelectUnity from './SelectUnity';

const SideMenu: React.FC = () => {
    const { user, setUser, setShowMenu } = useLayout();

    const navigate = useNavigate();

    const location = useLocation();

    let MENU = [
        {
            title: 'Dashboard',
            icon: FiCommand,
            link: '/dashboard',
            submenus: []
        },
        {
            title: 'Convidados',
            icon: FiUserPlus,
            link: '/guests',
            submenus: []
        },
        {
            title: 'Eventos',
            icon: FiCalendar,
            link: '/events',
            submenus: []
        },
        {
            title: 'Pesquisa de Opnião',
            icon: FiMeh,
            link: '/searches',
            submenus: []
        },
        {
            title: 'Recepcionistas',
            icon: FiUsers,
            link: '/receptionists',
            submenus: []
        },
        // {
        //     title: 'Relatórios',
        //     icon: FiTrendingUp,
        //     link: '/relatories',
        //     submenus: []
        // },
        {
            title: 'Usuários',
            icon: FiUser,
            link: '/users',
            submenus: []
        },
        {
            title: 'Whatsapp',
            icon: FiMessageCircle,
            link: '/whatsapp',
            submenus: []
        }
    ];

    if (
        user?.user.permissions.length > 0 &&
        !user.user.permissions.includes('REGISTER_INTERACTION')
    ) {
        MENU = MENU.filter(
            item =>
                item.title !== 'Pesquisa de Opnião' && item.title !== 'Whatsapp'
        );
    }

    const MENU_2 = [
        {
            title: 'Dashboard',
            icon: FiCommand,
            link: '/dashboard',
            submenus: []
        }
    ];

    const handleClickMenu = useCallback((item: any) => {
        navigate(item.link);
        setShowMenu(false);
    }, []);

    const handleLogout = useCallback(async () => {
        console.log(user);
        await apiBackend(user?.token).put(`/users/${user?.user.id}/status`, {
            status: 'OFFLINE'
        });

        setShowMenu(false);
        localStorage.clear();
        setUser(null);
        navigate('/login');
    }, [user]);

    return (
        <Flex
            width="296px"
            height="100vh"
            flexDirection="column"
            boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
            backgroundColor="gray.900"
            position="fixed"
        >
            <Flex
                width="100%"
                justifyContent="center"
                height="80px"
                backgroundColor="black"
                position="relative"
            >
                <Box>
                    <Image src={logoWhite} />
                </Box>

                <Flex
                    width="24px"
                    height="24px"
                    backgroundColor="orange.500"
                    justifyContent="center"
                    alignItems="center"
                    position="absolute"
                    top="0"
                    right="0"
                    onClick={() => setShowMenu(false)}
                    display={['flex', 'flex', 'flex', 'none']}
                >
                    <Icon as={FiX} color="white" />
                </Flex>
            </Flex>

            <SelectUnity />
            <Stack pl="16px" mt="auto">
                {user.user.type !== 'RECEPTIONIST' &&
                    MENU.map(item => (
                        <Flex
                            key={item.title}
                            height="32px"
                            alignItems="center"
                            onClick={() => handleClickMenu(item)}
                            cursor="pointer"
                            backgroundColor={
                                location.pathname === item.link
                                    ? 'gray.500'
                                    : 'gray.900'
                            }
                            pl="16px"
                        >
                            <Icon as={item.icon} color="orange.500" mr="8px" />
                            <Text color="gray.100" fontSize="14px">
                                {item.title}
                            </Text>
                        </Flex>
                    ))}

                {user.user.type === 'RECEPTIONIST' &&
                    MENU_2.map(item => (
                        <Flex
                            key={item.title}
                            height="32px"
                            alignItems="center"
                            onClick={() => handleClickMenu(item)}
                            cursor="pointer"
                            backgroundColor={
                                location.pathname === item.link
                                    ? 'gray.500'
                                    : 'gray.900'
                            }
                            pl="16px"
                        >
                            <Icon as={item.icon} color="orange.500" mr="8px" />
                            <Text color="gray.100" fontSize="14px">
                                {item.title}
                            </Text>
                        </Flex>
                    ))}

                <Flex
                    height="32px"
                    alignItems="center"
                    onClick={() => handleLogout()}
                    cursor="pointer"
                    pl="16px"
                >
                    <Icon as={FiLogOut} color="orange.500" mr="8px" />
                    <Text color="gray.100" fontSize="14px">
                        Sair
                    </Text>
                </Flex>
            </Stack>

            <Flex
                width="100%"
                mt="auto"
                justifyContent="center"
                py="8px"
                cursor="pointer"
            >
                {user.user.type !== 'RECEPTIONIST' && (
                    <Text
                        color="gray.100"
                        fontSize="14px"
                        onClick={() => {
                            navigate('/settings');
                        }}
                    >
                        Configurações
                    </Text>
                )}
            </Flex>
        </Flex>
    );
};

export default SideMenu;
