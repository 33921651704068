import React from 'react';

import {
    Text,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Flex,
    Button
} from '@chakra-ui/react';

interface IProps {
    isOpen: any;
    onClose: any;
    title?: any;
}

const ModalFinish: React.FC<IProps> = ({
    isOpen,
    onClose,
    title = 'Evento Encerrado!'
}) => {
    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
                <ModalOverlay />
                <ModalContent>
                    <ModalBody pb="8px">
                        <Flex
                            width="400px"
                            height="100px"
                            flexDirection="column"
                            alignItems="center"
                            backgroundColor="white"
                            px="24px"
                            py="8px"
                        >
                            <Text
                                fontSize="18px"
                                color="green.500"
                                fontWeight="600"
                            >
                                {title}
                            </Text>

                            <Text color="gray.600" fontWeight="500">
                                Não é possível adicionar convidados.
                            </Text>
                        </Flex>

                        <Flex
                            width="100%"
                            flexDirection="row"
                            justifyContent="center"
                            mt="16px"
                            mb="8px"
                            fontWeight="500"
                        >
                            <Button
                                width="48%"
                                backgroundColor="green.500"
                                color="white"
                                onClick={() => onClose()}
                                outline="none"
                                _hover={{
                                    backgroundColor: 'green.500'
                                }}
                            >
                                Fechar
                            </Button>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ModalFinish;
