import React from 'react';
import { Routes as RoutesDOM, Route } from 'react-router-dom';

import LoginPage from '../../modules/auth/pages/LoginPage';
import EventEditPage from '../../modules/events/pages/EditPage';
import EventListPage from '../../modules/events/pages/ListPage';
import EventRegisterPage from '../../modules/events/pages/RegisterPage';
import EventViewPage from '../../modules/events/pages/ViewPage';
import GuestListPage from '../../modules/guests/pages/ListPage';
import GuestRegisterPage from '../../modules/guests/pages/RegisterPage';
import SearchEditPage from '../../modules/opnions/pages/EditPage';
import OpnionListPage from '../../modules/opnions/pages/ListPage';
import SearchRegisterPage from '../../modules/opnions/pages/RegisterPage';
import PlansIndex from '../../modules/plans';
import ReceptionistEditPage from '../../modules/receptionists/pages/EditPage';
import ReceptionistListPage from '../../modules/receptionists/pages/ListPage';
import ReceptionistRegisterPage from '../../modules/receptionists/pages/RegisterPage';
import RelatoriesPage from '../../modules/relatories/pages/ListPage';
import GeneralSettingsPage from '../../modules/settings/pages/GeneralSettingsPage';
import UserEditPage from '../../modules/users/pages/EditPage';
import UserListPage from '../../modules/users/pages/ListPage';
import UserRegisterPage from '../../modules/users/pages/RegisterPage';
import WhatsappSettingsPage from '../../modules/whatsapp/SettingsPage';
import LayoutPanel from '../components/Layout/LayoutPanel';
import Dashboard from '../pages/Dashboard';

const Routes: React.FC = () => (
    <RoutesDOM>
        <Route path="/" element={<LayoutPanel component={<LoginPage />} />} />
        <Route
            path="/dashboard"
            element={<LayoutPanel component={<Dashboard />} />}
        />

        <Route
            path="/login"
            element={<LayoutPanel component={<LoginPage />} />}
        />

        <Route
            path="/events"
            element={<LayoutPanel component={<EventListPage />} />}
        />

        <Route
            path="/events/new"
            element={<LayoutPanel component={<EventRegisterPage />} />}
        />

        <Route
            path="/events/edit/:id"
            element={<LayoutPanel component={<EventEditPage />} />}
        />

        <Route
            path="/events/:id"
            element={<LayoutPanel component={<EventViewPage />} />}
        />

        <Route
            path="/receptionists"
            element={<LayoutPanel component={<ReceptionistListPage />} />}
        />

        <Route
            path="/receptionists/new"
            element={<LayoutPanel component={<ReceptionistRegisterPage />} />}
        />

        <Route
            path="/receptionists/edit/:id"
            element={<LayoutPanel component={<ReceptionistEditPage />} />}
        />

        <Route
            path="/users"
            element={<LayoutPanel component={<UserListPage />} />}
        />

        <Route
            path="/users/new"
            element={<LayoutPanel component={<UserRegisterPage />} />}
        />

        <Route
            path="/users/edit/:id"
            element={<LayoutPanel component={<UserEditPage />} />}
        />

        <Route
            path="/searches"
            element={<LayoutPanel component={<OpnionListPage />} />}
        />

        <Route
            path="/searches/new"
            element={<LayoutPanel component={<SearchRegisterPage />} />}
        />

        <Route
            path="/searches/edit/:id"
            element={<LayoutPanel component={<SearchEditPage />} />}
        />

        <Route
            path="/whatsapp"
            element={<LayoutPanel component={<WhatsappSettingsPage />} />}
        />

        <Route
            path="/settings"
            element={<LayoutPanel component={<GeneralSettingsPage />} />}
        />

        <Route
            path="/guests"
            element={<LayoutPanel component={<GuestListPage />} />}
        />

        <Route
            path="/events/:id/guests"
            element={<LayoutPanel component={<GuestRegisterPage />} />}
        />

        <Route
            path="/relatories"
            element={<LayoutPanel component={<RelatoriesPage />} />}
        />

        <Route
            path="/plans"
            element={<LayoutPanel component={<PlansIndex />} />}
        />
    </RoutesDOM>
);

export default Routes;
