import { addDays, format } from 'date-fns';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import { FiCalendar, FiEdit, FiEye } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Divider,
    Fade,
    Flex,
    Icon,
    Select,
    Stack,
    Text
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import apiBackend from '../../../../shared/apis';
import Button from '../../../../shared/components/Button';
import Calender from '../../../../shared/components/Calander';
import Form from '../../../../shared/components/Form';
import Input from '../../../../shared/components/Input';
import { HTTP_RESPONSE } from '../../../../shared/constants';
import { useLayout } from '../../../../shared/hooks/layout';
import TextUtils from '../../../../shared/utils/TextUtils';

const EventListPage: React.FC = () => {
    const navigate = useNavigate();
    const { user, currentUnity } = useLayout();

    const formRef = useRef<FormHandles>(null);

    const [events, setEvents] = useState([] as any);
    const [eventsDefault, setEventsDefault] = useState([] as any);
    const [eventsData, setEventsData] = useState({
        yesterday: [],
        today: [],
        tomorrow: []
    });

    const FIELDS = [
        {
            name: 'Nome',
            maxWidth: '100%',
            minWidth: '200px'
        },
        {
            name: 'Contratante',
            maxWidth: '100%',
            minWidth: '200px'
        },
        {
            name: 'Tipo de Festa',
            maxWidth: '100%',
            minWidth: '200px'
        },
        {
            name: 'Data',
            maxWidth: '120px',
            minWidth: '120px'
        },
        {
            name: 'Hora',
            maxWidth: '120px',
            minWidth: '120px'
        },
        {
            name: 'Status',
            maxWidth: '120px',
            minWidth: '200px'
        },
        {
            name: 'Ação',
            maxWidth: '120px',
            minWidth: '200px'
        }
    ];

    const [date, setDate] = useState(null as any);
    const [showCalendar, setShowCalendar] = useState(false);

    const handleChangeDeliveryDate = useCallback(async (value: Date) => {
        setDate(value);
        setShowCalendar(false);

        await apiBackend(user?.token)
            .get(`/events?date=${format(value, 'yyyy-MM-dd')}`)
            .then(response => {
                const { status, data } = response;

                if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                    setEvents(data);
                }
            });
    }, []);

    const handleSearch = useCallback(
        async (value: string) => {
            if (value) {
                await apiBackend(user?.token)
                    .get(`/events?name=${value}`)
                    .then(response => {
                        const { status, data } = response;

                        if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                            setEvents(data);
                        }
                    });
            } else {
                setEvents(eventsDefault);
            }
        },
        [currentUnity, eventsDefault]
    );

    const handleLoadData = useCallback(async () => {
        await apiBackend(user?.token)
            .get('/events')
            .then(response => {
                const { status, data } = response;

                if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                    const today = data.filter(
                        item =>
                            item.date === format(new Date(), 'yyyy-MM-dd') &&
                            item.unity_id === currentUnity.id
                    );

                    const tomorrow = data.filter(
                        item =>
                            item.date ===
                                format(addDays(new Date(), 1), 'yyyy-MM-dd') &&
                            item.unity_id === currentUnity.id
                    );

                    setEventsData({
                        yesterday: [],
                        today: today,
                        tomorrow: tomorrow
                    });

                    setEvents(data);
                    setEventsDefault(data);
                }
            });
    }, [user, currentUnity]);

    const sortedEvents = useMemo(() => {
        const sortedList = events.sort((a, b) => {
            return new Date(b.date).getTime() - new Date(a.date).getTime();
        });

        const started = sortedList.filter(item => item.status === 'STARTED');

        const waitingStarted = events
            .filter(item => item.status === 'WAITING_START')
            .sort(
                (a, b) =>
                    new Date(a.date).getTime() - new Date(b.date).getTime()
            );

        const finished = sortedList.filter(item => item.status === 'FINISHED');

        return [...started, ...waitingStarted, ...finished];
    }, [events]);

    const handleChangeTime = useCallback(
        (value: string) => {
            setEvents(eventsData[value]);
        },
        [eventsData]
    );

    const resetDateFilter = useCallback(() => {
        setDate(null as any);
        handleLoadData();
    }, []);

    const handleChangeStatus = useCallback(
        status => {
            if (status) {
                const newData = eventsDefault.filter(
                    item => item.status === status
                );
                setEvents(newData);
            } else {
                setEvents(eventsDefault);
            }
        },
        [eventsDefault]
    );

    useEffect(() => {
        handleLoadData();
    }, [handleLoadData]);

    return (
        <Form ref={formRef}>
            <Flex
                width="100%"
                flexDirection="column"
                onClick={() => {
                    if (showCalendar) {
                        setShowCalendar(false);
                    }
                }}
            >
                <Breadcrumb
                    mb="16px"
                    fontWeight="500"
                    color="gray.900"
                    fontSize="14px"
                >
                    <BreadcrumbItem>
                        <BreadcrumbLink href="/dashboard">
                            Início
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem isCurrentPage>
                        <BreadcrumbLink href="/events">Eventos</BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>

                <Flex
                    width="100%"
                    p="16px"
                    justifyContent="space-between"
                    backgroundColor="white"
                    alignItems="center"
                >
                    {(user?.user.permissions.length === 0 ||
                        user?.user.permissions.includes('REGISTER_EVENT')) && (
                        <Button
                            title="+ Novo Evento"
                            backgroundColor="green.500"
                            color="white"
                            width="192px"
                            minWidth="192px"
                            borderRadius="4px"
                            py="8px"
                            fontSize="14px"
                            onClick={() => navigate('/events/new')}
                        />
                    )}

                    <Flex width="600px">
                        <Flex mr="24px">
                            <Input
                                name="search"
                                placeholder="Pesquisar por Nome"
                                width="160px"
                                mb="0px"
                                onChange={e =>
                                    handleSearch(e.currentTarget.value)
                                }
                            />
                        </Flex>

                        <Select
                            name="search-status"
                            placeholder="Pesquisar por Status"
                            width="200px"
                            mb="0px"
                            onChange={e =>
                                handleChangeStatus(e.currentTarget.value)
                            }
                            size="sm"
                        >
                            <option value="STARTED">Iniciado</option>
                            <option value="WAITING_START">
                                Aguardando Início
                            </option>
                            <option value="FINISHED">Encerrado</option>
                        </Select>

                        <Flex
                            width="40px"
                            whiteSpace="nowrap"
                            alignItems="center"
                            px="16px"
                            cursor="pointer"
                            onClick={() => {
                                setEvents(eventsDefault);
                            }}
                            fontWeight="600"
                        >
                            <Text fontSize="12px">LIMPAR FILTRO</Text>
                        </Flex>
                    </Flex>

                    <Stack
                        direction="row"
                        alignItems="center"
                        display={['none', 'none', 'none', 'flex']}
                    >
                        <Text fontWeight="500" fontSize="14px" color="gray.500">
                            Filtrar por período
                        </Text>

                        <Flex
                            backgroundColor="gray.100"
                            justifyContent="center"
                            alignItems="center"
                            p="8px"
                            borderRadius="4px"
                            cursor="pointer"
                            onClick={() =>
                                date
                                    ? resetDateFilter()
                                    : setShowCalendar(!showCalendar)
                            }
                            _hover={{
                                border: date ? '2px solid' : 'none',
                                borderColor: 'red.500'
                            }}
                            title={date ? 'Remover' : 'Filtrar'}
                        >
                            <Icon
                                as={FiCalendar}
                                color="green.500"
                                fontSize="18px"
                            />

                            {date && (
                                <Text
                                    ml="8px"
                                    fontSize="12px"
                                    fontWeight="500"
                                    color="gray.900"
                                >
                                    {format(date, 'dd/MM/yy')}
                                </Text>
                            )}
                        </Flex>

                        <Fade in={showCalendar}>
                            <Flex display={showCalendar ? 'flex' : 'none'}>
                                <Calender
                                    isErrored={false}
                                    selectedDate={date}
                                    handleDateChange={handleChangeDeliveryDate}
                                    handleMonthChange={undefined}
                                    onDayMouseUp={() => setShowCalendar(false)}
                                />
                            </Flex>
                        </Fade>
                    </Stack>
                </Flex>

                <Flex
                    width="100%"
                    flexDirection="column"
                    backgroundColor="white"
                    p="16px"
                >
                    <Flex width="100%" justifyContent="space-between">
                        <Text fontWeight="600" color="green.500">
                            Eventos
                        </Text>

                        <Stack direction="row">
                            <Button
                                width="64px"
                                height="32px"
                                title="Hoje"
                                backgroundColor="green.500"
                                color="white"
                                borderRadius="2px"
                                fontWeight="500"
                                fontSize="12px"
                                onClick={() => handleChangeTime('today')}
                            />
                            <Button
                                width="64px"
                                height="32px"
                                title="Amanhã"
                                backgroundColor="red.500"
                                color="white"
                                borderRadius="2px"
                                fontWeight="500"
                                fontSize="12px"
                                onClick={() => handleChangeTime('tomorrow')}
                            />
                        </Stack>
                    </Flex>

                    <Divider my="16px" />

                    <Flex width="100%" flexDirection="column" overflowX="auto">
                        <Flex
                            width="100%"
                            borderBottom="1px solid"
                            borderColor="gray.200"
                            pb="6px"
                        >
                            {FIELDS.map(field => (
                                <Flex
                                    key={field.name}
                                    width="100%"
                                    maxWidth={field.maxWidth}
                                    fontSize="14px"
                                    fontWeight="600"
                                >
                                    <Text>{field.name}</Text>
                                </Flex>
                            ))}
                        </Flex>
                        <Flex
                            width="100%"
                            flexDirection="column"
                            fontSize="12px"
                        >
                            {sortedEvents.map(event => (
                                <Flex
                                    key={event.id}
                                    width="100%"
                                    py="8px"
                                    borderBottom="1px solid"
                                    borderColor="gray.200"
                                >
                                    <Flex width="100%" maxWidth="100%">
                                        <Text>{event.name}</Text>
                                    </Flex>

                                    <Flex width="100%" maxWidth="100%">
                                        <Text>{event.contractor_name_1}</Text>
                                    </Flex>

                                    <Flex width="100%" maxWidth="100%">
                                        <Text>{event.type}</Text>
                                    </Flex>

                                    <Flex width="100%" maxWidth="120px">
                                        <Text>
                                            {format(
                                                addDays(
                                                    new Date(event.date),
                                                    1
                                                ),
                                                'dd/MM/yy'
                                            )}
                                        </Text>
                                    </Flex>

                                    <Flex width="100%" maxWidth="120px">
                                        <Text>{event.start_hour}</Text>
                                    </Flex>

                                    <Flex width="100%" maxWidth="120px">
                                        <Text
                                            color={TextUtils.convertEventStatusColor(
                                                event.status
                                            )}
                                            fontWeight="600"
                                        >
                                            {TextUtils.convertEventStatus(
                                                event.status
                                            )}
                                        </Text>
                                    </Flex>

                                    <Flex width="100%" maxWidth="120px">
                                        <Stack direction="row">
                                            {event.status !== 'FINISHED' && (
                                                <Flex
                                                    width="24px"
                                                    height="24px"
                                                    backgroundColor="gray.900"
                                                    color="white"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    title="Editar"
                                                    cursor="pointer"
                                                    borderRadius="50%"
                                                    onClick={() =>
                                                        navigate(
                                                            `/events/edit/${event.id}`
                                                        )
                                                    }
                                                >
                                                    <Icon
                                                        as={FiEdit}
                                                        fontSize="18px"
                                                        color="white"
                                                    />
                                                </Flex>
                                            )}

                                            <Flex
                                                width="24px"
                                                height="24px"
                                                backgroundColor="green.500"
                                                color="white"
                                                justifyContent="center"
                                                alignItems="center"
                                                title="Visualizar"
                                                cursor="pointer"
                                                borderRadius="50%"
                                                onClick={() =>
                                                    navigate(
                                                        `/events/${event.id}`
                                                    )
                                                }
                                            >
                                                <Icon
                                                    as={FiEye}
                                                    fontSize="18px"
                                                    color="white"
                                                />
                                            </Flex>
                                        </Stack>
                                    </Flex>
                                </Flex>
                            ))}
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Form>
    );
};

export default EventListPage;
