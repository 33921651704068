import React, { useCallback, useEffect, useState } from 'react';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Divider,
    Flex,
    Icon,
    Stack,
    Text,
    useDisclosure
} from '@chakra-ui/react';

import apiBackend from '../../../../shared/apis';
import Button from '../../../../shared/components/Button';
import Form from '../../../../shared/components/Form';
import ModalDeleteConfirmation from '../../../../shared/components/ModalDeleteConfirmation';
import { HTTP_RESPONSE } from '../../../../shared/constants';
import { useLayout } from '../../../../shared/hooks/layout';

const ReceptionistListPage: React.FC = () => {
    const navigate = useNavigate();

    const { user } = useLayout();

    const [users, setUsers] = useState<any[]>([]);

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [selectedItem, setSelectedItem] = useState('');

    const FIELDS = [
        {
            name: 'Nome',
            maxWidth: '100%'
        },
        {
            name: 'Telefone',
            maxWidth: '100%'
        },
        {
            name: 'Num. Eventos',
            maxWidth: '120px'
        },
        {
            name: 'Status',
            maxWidth: '120px'
        },
        {
            name: 'Ação',
            maxWidth: '120px'
        }
    ];

    const handleDelete = useCallback(async (id: string) => {
        setSelectedItem(id);
        onOpen();
    }, []);

    const deleteFunction = useCallback(() => {
        apiBackend(user?.token)
            .put(`/users/${selectedItem}/delete`)
            .then(response => {
                const { status } = response;

                if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                    setUsers(oldState =>
                        oldState.filter(item => item.id !== selectedItem)
                    );
                }
            });
    }, [user, selectedItem]);

    useEffect(() => {
        apiBackend(user?.token)
            .get('/users?type=RECEPTIONIST')
            .then(response => {
                const { status, data } = response;

                if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                    setUsers(data);
                }
            });
    }, [user]);

    return (
        <Form>
            <Flex width="100%" flexDirection="column">
                <Breadcrumb
                    mb="16px"
                    fontWeight="500"
                    color="gray.900"
                    fontSize="14px"
                >
                    <BreadcrumbItem>
                        <BreadcrumbLink href="/dashboard">
                            Início
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem isCurrentPage>
                        <BreadcrumbLink href="/receptionists">
                            Recepcionistas
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>

                <Flex
                    width="100%"
                    p="16px"
                    justifyContent="space-between"
                    backgroundColor="white"
                    alignItems="center"
                    flexDirection="column"
                >
                    {(user?.user.permissions.length === 0 ||
                        user?.user.permissions.includes(
                            'REGISTER_RECEPTIONIST'
                        )) && (
                        <Flex width="100%" justifyContent="space-between">
                            <Button
                                title="+ Nova Recepcionista"
                                backgroundColor="green.500"
                                color="white"
                                width="192px"
                                minWidth="192px"
                                borderRadius="4px"
                                py="8px"
                                fontSize="14px"
                                onClick={() => navigate('/receptionists/new')}
                            />
                        </Flex>
                    )}

                    <Flex width="100%" flexDirection="column" mt="24px">
                        <Flex width="100%" justifyContent="space-between">
                            <Text fontWeight="600" color="green.500">
                                Recepcionistas
                            </Text>
                        </Flex>

                        <Divider my="16px" />

                        <Flex
                            width="100%"
                            flexDirection="column"
                            overflow="auto"
                        >
                            <Flex width="100%">
                                {FIELDS.map(field => (
                                    <Flex
                                        key={field.name}
                                        width="100%"
                                        maxWidth={field.maxWidth}
                                        fontSize="14px"
                                        fontWeight="600"
                                    >
                                        <Text>{field.name}</Text>
                                    </Flex>
                                ))}
                            </Flex>
                            <Flex
                                width="100%"
                                flexDirection="column"
                                fontSize="14px"
                            >
                                {users.map(receptionist => (
                                    <Flex width="100%" py="8px">
                                        <Flex width="100%" maxWidth="100%">
                                            <Text>{receptionist.name}</Text>
                                        </Flex>

                                        <Flex width="100%" maxWidth="100%">
                                            <Text>{receptionist.whatsapp}</Text>
                                        </Flex>

                                        <Flex width="100%" maxWidth="120px">
                                            {receptionist.events.length}
                                        </Flex>

                                        <Flex width="100%" maxWidth="120px">
                                            <Button
                                                width="96px"
                                                title={receptionist.status}
                                                backgroundColor={
                                                    receptionist.status ===
                                                    'ONLINE'
                                                        ? 'green.500'
                                                        : 'gray.900'
                                                }
                                                color="white"
                                                fontWeight="500"
                                                fontSize="12px"
                                            />
                                        </Flex>

                                        <Flex width="100%" maxWidth="120px">
                                            <Stack direction="row">
                                                <Flex
                                                    width="24px"
                                                    height="24px"
                                                    backgroundColor="gray.900"
                                                    color="white"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    title="Editar"
                                                    cursor="pointer"
                                                    borderRadius="50%"
                                                    onClick={() =>
                                                        navigate(
                                                            `/receptionists/edit/${receptionist.id}`
                                                        )
                                                    }
                                                >
                                                    <Icon
                                                        as={FiEdit}
                                                        fontSize="18px"
                                                        color="white"
                                                    />
                                                </Flex>

                                                <Flex
                                                    width="24px"
                                                    height="24px"
                                                    backgroundColor="red.500"
                                                    color="white"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    title="Excluir"
                                                    cursor="pointer"
                                                    borderRadius="50%"
                                                    onClick={() =>
                                                        handleDelete(
                                                            receptionist.id
                                                        )
                                                    }
                                                >
                                                    <Icon
                                                        as={FiTrash2}
                                                        fontSize="18px"
                                                    />
                                                </Flex>
                                            </Stack>
                                        </Flex>
                                    </Flex>
                                ))}
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>

                <ModalDeleteConfirmation
                    deleteFunction={deleteFunction}
                    isOpen={isOpen}
                    onClose={onClose}
                />
            </Flex>
        </Form>
    );
};

export default ReceptionistListPage;
