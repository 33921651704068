import { format } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
    Avatar,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Divider,
    Flex,
    Text
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import apiBackend from '../../../../shared/apis';
import Form from '../../../../shared/components/Form';
import Input from '../../../../shared/components/Input';
import { HTTP_RESPONSE } from '../../../../shared/constants';
import { useLayout } from '../../../../shared/hooks/layout';

const GuestListPage: React.FC = () => {
    const formRef = useRef<FormHandles>(null);
    const { user } = useLayout();

    const [guests, setGuests] = useState([] as any);

    const FIELDS = [
        {
            name: '',
            minWidth: '80px'
        },
        {
            name: 'Nome',
            minWidth: '100px'
        },
        {
            name: 'Whatsapp',
            minWidth: '144px'
        },
        {
            name: 'Sexo',
            minWidth: '100px'
        },
        {
            name: 'Tipo',
            minWidth: '120px'
        },
        {
            name: 'Visitas',
            minWidth: '100px'
        },
        {
            name: 'Ultima Visita',
            minWidth: '120px'
        }
    ];

    const [showCalendar, setShowCalendar] = useState(false);

    const loadData = useCallback(() => {
        apiBackend(user?.token)
            .get(`/guests`)
            .then(response => {
                const { status, data } = response;
                if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                    setGuests(data);
                }
            });
    }, [user]);

    const handleSearch = useCallback(
        async (value: string) => {
            if (value) {
                await apiBackend(user?.token)
                    .get(`/guests?name=${value}`)
                    .then(response => {
                        const { status, data } = response;
                        if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                            setGuests(data);
                        }
                    });
            } else {
                loadData();
            }
        },
        [user, loadData]
    );

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <Form ref={formRef}>
            <Flex
                width="100%"
                flexDirection="column"
                onClick={() => {
                    if (showCalendar) {
                        setShowCalendar(false);
                    }
                }}
            >
                <Breadcrumb
                    mb="16px"
                    fontWeight="500"
                    color="gray.900"
                    fontSize="14px"
                >
                    <BreadcrumbItem>
                        <BreadcrumbLink href="/dashboard">
                            Início
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem isCurrentPage>
                        <BreadcrumbLink href="/guests">
                            Convidados
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>

                <Flex
                    width="100%"
                    flexDirection="column"
                    backgroundColor="white"
                    p="16px"
                >
                    <Flex
                        width="100%"
                        justifyContent="space-between"
                        flexWrap={'wrap'}
                    >
                        <Text fontWeight="600" color="green.500">
                            Convidados
                        </Text>

                        <Input
                            name="search"
                            placeholder="Pesquisar por Nome"
                            maxWidth="400px"
                            mb="0px"
                            onChange={e => handleSearch(e.currentTarget.value)}
                        />
                    </Flex>

                    <Divider my="16px" />

                    <Flex width="100%" flexDirection="column" overflowX="auto">
                        <Flex
                            width="100%"
                            borderBottom="1px solid"
                            borderColor="gray.200"
                            pb="6px"
                        >
                            {FIELDS.map(field => (
                                <Flex
                                    key={field.name}
                                    width="100%"
                                    minWidth={field.minWidth}
                                    fontSize="14px"
                                    fontWeight="600"
                                >
                                    <Text>{field.name}</Text>
                                </Flex>
                            ))}
                        </Flex>
                        <Flex
                            width="100%"
                            flexDirection="column"
                            fontSize="12px"
                        >
                            {guests.map(guest => (
                                <Flex
                                    key={guest.id}
                                    width="100%"
                                    py="8px"
                                    borderBottom="1px solid"
                                    borderColor="gray.200"
                                    alignItems="center"
                                >
                                    <Flex
                                        width="100%"
                                        minWidth={FIELDS[0].minWidth}
                                    >
                                        <Avatar
                                            size="md"
                                            name={guest.name}
                                            src={guest.avatar}
                                        />
                                    </Flex>
                                    <Flex
                                        width="100%"
                                        minWidth={FIELDS[1].minWidth}
                                    >
                                        <Text>{guest.name}</Text>
                                    </Flex>

                                    <Flex
                                        width="100%"
                                        minWidth={FIELDS[2].minWidth}
                                    >
                                        <Text>{guest.telephone}</Text>
                                    </Flex>

                                    <Flex
                                        width="100%"
                                        minWidth={FIELDS[3].minWidth}
                                    >
                                        <Text>{guest.sex}</Text>
                                    </Flex>

                                    <Flex
                                        width="100%"
                                        minWidth={FIELDS[4].minWidth}
                                    >
                                        <Text>
                                            {guest.is_children
                                                ? 'Criança'
                                                : 'Adulto'}
                                        </Text>
                                    </Flex>

                                    <Flex
                                        width="100%"
                                        minWidth={FIELDS[5].minWidth}
                                    >
                                        <Text>{guest.num_visits}</Text>
                                    </Flex>

                                    <Flex
                                        width="100%"
                                        minWidth={FIELDS[6].minWidth}
                                    >
                                        <Text>
                                            {format(
                                                new Date(guest.updated_at),
                                                'dd/MM/yy'
                                            )}
                                        </Text>
                                    </Flex>
                                </Flex>
                            ))}
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Form>
    );
};

export default GuestListPage;
