import { addDays, format, subDays } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { FiEye } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import { Divider, Flex, Icon, Stack, Text } from '@chakra-ui/react';

import apiBackend from '../apis';
import Button from '../components/Button';
import Card from '../components/Card';
import { HTTP_RESPONSE } from '../constants';
import { useLayout } from '../hooks/layout';
import TextUtils from '../utils/TextUtils';

const Dashboard: React.FC = () => {
    const { user, currentUnity } = useLayout();

    const [eventData, setEventData] = useState<any>({});
    const [events, setEvents] = useState<any[]>([]);
    const [users, setUsers] = useState<any[]>([]);

    const [receptionistData, setReceptionistData] = useState({
        yesterday: [],
        today: [],
        tomorrow: []
    });

    const navigate = useNavigate();

    const FIELDS = [
        {
            name: 'Evento',
            maxWidth: '100%',
            minWidth: '100px'
        },
        {
            name: 'Responsável',
            maxWidth: '100%',
            minWidth: '100px'
        },
        {
            name: 'Tel. Responsável',
            maxWidth: '100%',
            minWidth: '100px'
        },
        {
            name: 'Início',
            maxWidth: '120px',
            minWidth: '120px'
        },
        {
            name: 'Término',
            maxWidth: '120px',
            minWidth: '120px'
        },
        {
            name: 'Status',
            maxWidth: '120px',
            minWidth: '120px'
        },
        {
            name: 'Ação',
            maxWidth: '120px',
            minWidth: '120px'
        }
    ];

    const FIELDS2 = [
        {
            name: 'Nome',
            maxWidth: '100%',
            minWidth: '200px'
        },
        {
            name: 'Telefone',
            maxWidth: '100%',
            minWidth: '200px'
        },
        {
            name: 'Num. Eventos',
            maxWidth: '100%',
            minWidth: '200px'
        },
        {
            name: 'Status',
            maxWidth: '100%',
            minWidth: '200px'
        }
    ];

    const handleLoadData = useCallback(async () => {
        await apiBackend(user?.token)
            .get(`/events/data?unity_id=${currentUnity.id}`)
            .then(response => {
                const { status, data } = response;

                if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                    setEventData(data);
                }
            });

        await apiBackend(user?.token)
            .get('/events')
            .then(response => {
                const { status, data } = response;

                if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                    const today = data.filter(
                        item =>
                            item.date === format(new Date(), 'yyyy-MM-dd') &&
                            item.unity_id === currentUnity.id
                    );

                    const tomorrow = data.filter(
                        item =>
                            item.date ===
                                format(addDays(new Date(), 1), 'yyyy-MM-dd') &&
                            item.unity_id === currentUnity.id
                    );

                    setReceptionistData({
                        yesterday: [],
                        today: today,
                        tomorrow: tomorrow
                    });

                    setEvents(today);
                }
            });

        await apiBackend(user?.token)
            .get('/users?type=RECEPTIONIST')
            .then(response => {
                const { status, data } = response;

                if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                    setUsers(data);
                }
            });
    }, [user, currentUnity]);

    const handleLoadReceptionistData = useCallback(async () => {
        await apiBackend(user?.token)
            .get(`/users/${user?.user.id}/events?unity_id=${currentUnity.id}`)
            .then(response => {
                const { status, data } = response;

                if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                    const yesterday = data.filter(
                        item =>
                            item.date ===
                                format(subDays(new Date(), 1), 'yyyy-MM-dd') &&
                            item.unity_id === currentUnity.id
                    );

                    const today = data.filter(
                        item =>
                            item.date === format(new Date(), 'yyyy-MM-dd') &&
                            item.unity_id === currentUnity.id
                    );

                    const tomorrow = data.filter(
                        item =>
                            item.date ===
                                format(addDays(new Date(), 1), 'yyyy-MM-dd') &&
                            item.unity_id === currentUnity.id
                    );

                    setReceptionistData({
                        yesterday: yesterday,
                        today: today,
                        tomorrow: tomorrow
                    });

                    setEvents(today);
                }
            });
    }, [user, currentUnity]);

    const handleChangeTime = useCallback(
        (value: string) => {
            setEvents(receptionistData[value]);
        },
        [receptionistData]
    );

    useEffect(() => {
        if (user?.user.type !== 'RECEPTIONIST') {
            handleLoadData();
        } else {
            handleLoadReceptionistData();
        }
    }, [user, handleLoadData, handleLoadReceptionistData]);

    return user?.user.type !== 'RECEPTIONIST' ? (
        <Flex width="100%" flexDirection="column">
            <Flex
                width="100%"
                flexWrap={['nowrap', 'nowrap', 'nowrap', 'wrap']}
                overflow="auto"
            >
                <Card
                    title="Participantes"
                    value="02"
                    color="red.500"
                    customContent={
                        <Flex width="100%" justifyContent="space-between">
                            <Flex width="48%" flexDirection="column">
                                <Text color="red.500" fontSize="12px">
                                    Pagante
                                </Text>
                                <Text
                                    fontWeight="600"
                                    fontSize="40px"
                                    color="green.500"
                                >
                                    {eventData?.numAdults}
                                </Text>
                            </Flex>

                            <Divider orientation="vertical" />

                            <Flex
                                width="48%"
                                flexDirection="column"
                                alignItems="flex-end"
                            >
                                <Text color="red.500" fontSize="12px">
                                    Não Pagante
                                </Text>
                                <Text
                                    fontWeight="600"
                                    fontSize="40px"
                                    color="green.500"
                                >
                                    {eventData?.numChildrens}
                                </Text>
                            </Flex>
                        </Flex>
                    }
                />
                <Card
                    title="Em andamento"
                    value={eventData?.started?.length || 0}
                    color="red.500"
                />
                <Card
                    title="Por iniciar"
                    value={eventData?.waitingStart?.length || 0}
                    color="yellow.500"
                />
                <Card
                    title="Finalizado"
                    value={eventData?.finished?.length || 0}
                    color="purple.500"
                />
                <Card
                    title="Participantes cadastrados"
                    value={eventData?.numAdults + eventData?.numChildrens || 0}
                    color="red.500"
                />
                <Card
                    title="Adultos"
                    value={eventData?.numAdults || 0}
                    color="yellow.500"
                />
                <Card
                    title="Menores"
                    value={eventData?.numChildrens || 0}
                    color="purple.500"
                />
                <Card
                    title="Eventos na casa Hoje"
                    value={receptionistData?.today?.length || 0}
                    color="green.500"
                />
            </Flex>

            <Flex
                width="100%"
                flexDirection="column"
                backgroundColor="white"
                p="16px"
            >
                <Flex width="100%" justifyContent="space-between">
                    <Text fontWeight="600" color="green.500">
                        Eventos
                    </Text>

                    <Stack direction="row">
                        <Button
                            width="64px"
                            title="Hoje"
                            backgroundColor="green.500"
                            color="white"
                            borderRadius="2px"
                            fontWeight="500"
                            fontSize="12px"
                            onClick={() => handleChangeTime('today')}
                        />
                        <Button
                            width="64px"
                            title="Amanhã"
                            backgroundColor="red.500"
                            color="white"
                            borderRadius="2px"
                            fontWeight="500"
                            fontSize="12px"
                            onClick={() => handleChangeTime('tomorrow')}
                        />
                    </Stack>
                </Flex>

                <Divider my="16px" />

                {events.length > 0 && (
                    <Flex width="100%" flexDirection="column" overflow="auto">
                        <Flex
                            width="100%"
                            borderBottom="1px solid"
                            borderColor="gray.200"
                            pb="6px"
                        >
                            {FIELDS.map(field => (
                                <Flex
                                    key={field.name}
                                    width="100%"
                                    maxWidth={field.maxWidth}
                                    minWidth={field.minWidth}
                                    fontSize="14px"
                                    fontWeight="600"
                                >
                                    <Text>{field.name}</Text>
                                </Flex>
                            ))}
                        </Flex>
                        <Flex
                            width="100%"
                            flexDirection="column"
                            fontSize="12px"
                        >
                            {events.map(event => (
                                <Flex
                                    key={event.id}
                                    width="100%"
                                    py="8px"
                                    borderBottom="1px solid"
                                    borderColor="gray.200"
                                >
                                    <Flex
                                        width="100%"
                                        maxWidth="100%"
                                        minWidth={FIELDS[0].minWidth}
                                        onClick={() =>
                                            navigate(`/events/${event.id}`)
                                        }
                                        textDecoration="underline"
                                    >
                                        <Text>{event.name}</Text>
                                    </Flex>

                                    <Flex
                                        width="100%"
                                        maxWidth="100%"
                                        minWidth={FIELDS[0].minWidth}
                                    >
                                        <Text>{event.contractor_name_1}</Text>
                                    </Flex>

                                    <Flex
                                        width="100%"
                                        maxWidth="100%"
                                        minWidth={FIELDS[0].minWidth}
                                    >
                                        <Text>
                                            {event.contractor_telephone_1}
                                        </Text>
                                    </Flex>

                                    <Flex
                                        width="100%"
                                        maxWidth="120px"
                                        minWidth={FIELDS[0].minWidth}
                                    >
                                        <Text>{event.start_hour}</Text>
                                    </Flex>

                                    <Flex
                                        width="100%"
                                        maxWidth="120px"
                                        minWidth={FIELDS[0].minWidth}
                                    >
                                        <Text>{event.end_hour}</Text>
                                    </Flex>

                                    <Flex
                                        width="100%"
                                        minWidth={FIELDS[0].minWidth}
                                        maxWidth="120px"
                                    >
                                        <Text
                                            color={TextUtils.convertEventStatusColor(
                                                event.status
                                            )}
                                            fontWeight="600"
                                        >
                                            {TextUtils.convertEventStatus(
                                                event.status
                                            )}
                                        </Text>
                                    </Flex>

                                    <Flex
                                        width="100%"
                                        maxWidth="120px"
                                        minWidth={FIELDS[0].minWidth}
                                    >
                                        <Flex
                                            width="24px"
                                            height="24px"
                                            backgroundColor="green.500"
                                            color="white"
                                            justifyContent="center"
                                            alignItems="center"
                                            title="Visualizar"
                                            cursor="pointer"
                                            borderRadius="50%"
                                            onClick={() =>
                                                navigate(`/events/${event.id}`)
                                            }
                                        >
                                            <Icon
                                                as={FiEye}
                                                fontSize="18px"
                                                color="white"
                                            />
                                        </Flex>
                                    </Flex>
                                </Flex>
                            ))}
                        </Flex>
                    </Flex>
                )}
                {events.length <= 0 && (
                    <Flex
                        width="100%"
                        backgroundColor="yellow.200"
                        justifyContent="center"
                        alignItems="center"
                        height="40px"
                    >
                        <Text fontSize="14PX" fontWeight="600" color="gray.500">
                            Nenhum evento no momento
                        </Text>
                    </Flex>
                )}
            </Flex>

            <Flex
                width="100%"
                flexDirection="column"
                backgroundColor="white"
                p="16px"
                mt="24px"
            >
                <Flex width="100%" justifyContent="space-between">
                    <Text fontWeight="600" color="green.500">
                        Recepcionistas
                    </Text>
                </Flex>

                <Divider my="16px" />

                <Flex width="100%" flexDirection="column" overflow="auto">
                    <Flex
                        width="100%"
                        borderBottom="1px solid"
                        borderColor="gray.200"
                        pb="6px"
                    >
                        {FIELDS2.map(field => (
                            <Flex
                                key={field.name}
                                width="100%"
                                maxWidth={field.maxWidth}
                                minWidth={field.minWidth}
                                fontSize="14px"
                                fontWeight="600"
                            >
                                <Text>{field.name}</Text>
                            </Flex>
                        ))}
                    </Flex>
                    <Flex width="100%" flexDirection="column" fontSize="12px">
                        {users.map(receptionist => (
                            <Flex
                                key={receptionist.id}
                                width="100%"
                                py="8px"
                                borderBottom="1px solid"
                                borderColor="gray.200"
                            >
                                <Flex
                                    width="100%"
                                    maxWidth="100%"
                                    minWidth={FIELDS[0].minWidth}
                                >
                                    <Text>{receptionist.name}</Text>
                                </Flex>

                                <Flex
                                    width="100%"
                                    maxWidth="100%"
                                    minWidth={FIELDS[0].minWidth}
                                >
                                    <Text>{receptionist.whatsapp}</Text>
                                </Flex>

                                <Flex
                                    width="100%"
                                    maxWidth="100%"
                                    minWidth={FIELDS[0].minWidth}
                                >
                                    <Text>{receptionist.events.length}</Text>
                                </Flex>

                                <Flex
                                    width="100%"
                                    maxWidth="100%"
                                    minWidth={FIELDS[0].minWidth}
                                >
                                    <Button
                                        width="96px"
                                        title={receptionist.status}
                                        backgroundColor={
                                            receptionist.status === 'ONLINE'
                                                ? 'green.500'
                                                : 'gray.900'
                                        }
                                        color="white"
                                        fontWeight="500"
                                        fontSize="12px"
                                    />
                                </Flex>
                            </Flex>
                        ))}
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    ) : (
        <Flex width="100%" flexDirection="column">
            <Flex
                width="100%"
                flexWrap={['nowrap', 'nowrap', 'nowrap', 'wrap']}
                overflow="auto"
            >
                <Card
                    title="Meus Eventos Hoje"
                    value={receptionistData.today.length || 0}
                    color="green.500"
                />
            </Flex>

            <Flex
                width="100%"
                flexDirection="column"
                backgroundColor="white"
                p="16px"
            >
                <Flex width="100%" justifyContent="space-between">
                    <Text fontWeight="600" color="green.500">
                        Eventos
                    </Text>

                    <Stack direction="row">
                        <Button
                            width="64px"
                            title="Ontem"
                            backgroundColor="orange.500"
                            color="white"
                            borderRadius="2px"
                            fontWeight="500"
                            fontSize="12px"
                            onClick={() => handleChangeTime('yesterday')}
                        />

                        <Button
                            width="64px"
                            title="Hoje"
                            backgroundColor="green.500"
                            color="white"
                            borderRadius="2px"
                            fontWeight="500"
                            fontSize="12px"
                            onClick={() => handleChangeTime('today')}
                        />
                        <Button
                            width="64px"
                            title="Amanhã"
                            backgroundColor="red.500"
                            color="white"
                            borderRadius="2px"
                            fontWeight="500"
                            fontSize="12px"
                            onClick={() => handleChangeTime('tomorrow')}
                        />
                    </Stack>
                </Flex>

                <Divider my="16px" />

                {events.length > 0 && (
                    <Flex width="100%" flexDirection="column" overflow="auto">
                        <Flex
                            width="100%"
                            borderBottom="1px solid"
                            borderColor="gray.200"
                            pb="6px"
                        >
                            {FIELDS.map(field => (
                                <Flex
                                    key={field.name}
                                    width="100%"
                                    maxWidth={field.maxWidth}
                                    minWidth={field.minWidth}
                                    fontSize="14px"
                                    fontWeight="600"
                                >
                                    <Text>{field.name}</Text>
                                </Flex>
                            ))}
                        </Flex>

                        <Flex
                            width="100%"
                            flexDirection="column"
                            fontSize="12px"
                        >
                            {events.map(event => (
                                <Flex
                                    key={event.id}
                                    width="100%"
                                    py="8px"
                                    borderBottom="1px solid"
                                    borderColor="gray.200"
                                >
                                    <Flex
                                        width="100%"
                                        maxWidth="100%"
                                        minWidth={FIELDS[0].minWidth}
                                        onClick={() =>
                                            navigate(`/events/${event.id}`)
                                        }
                                        textDecoration="underline"
                                    >
                                        <Text>{event.name}</Text>
                                    </Flex>

                                    <Flex
                                        width="100%"
                                        maxWidth="100%"
                                        minWidth={FIELDS[0].minWidth}
                                    >
                                        <Text>{event.contractor_name_1}</Text>
                                    </Flex>

                                    <Flex
                                        width="100%"
                                        maxWidth="100%"
                                        minWidth={FIELDS[0].minWidth}
                                    >
                                        <Text>
                                            {event.contractor_telephone_1}
                                        </Text>
                                    </Flex>

                                    <Flex
                                        width="100%"
                                        maxWidth="120px"
                                        minWidth={FIELDS[0].minWidth}
                                    >
                                        <Text>{event.start_hour}</Text>
                                    </Flex>

                                    <Flex
                                        width="100%"
                                        maxWidth="120px"
                                        minWidth={FIELDS[0].minWidth}
                                    >
                                        <Text>{event.end_hour}</Text>
                                    </Flex>

                                    <Flex
                                        width="100%"
                                        minWidth={FIELDS[0].minWidth}
                                        maxWidth="120px"
                                    >
                                        <Text
                                            color={TextUtils.convertEventStatusColor(
                                                event.status
                                            )}
                                            fontWeight="600"
                                        >
                                            {TextUtils.convertEventStatus(
                                                event.status
                                            )}
                                        </Text>
                                    </Flex>

                                    <Flex width="100%" maxWidth="120px">
                                        <Flex
                                            width="24px"
                                            height="24px"
                                            backgroundColor="green.500"
                                            color="white"
                                            justifyContent="center"
                                            alignItems="center"
                                            title="Visualizar"
                                            cursor="pointer"
                                            borderRadius="50%"
                                            onClick={() =>
                                                navigate(`/events/${event.id}`)
                                            }
                                        >
                                            <Icon
                                                as={FiEye}
                                                fontSize="18px"
                                                color="white"
                                            />
                                        </Flex>
                                    </Flex>
                                </Flex>
                            ))}
                        </Flex>
                    </Flex>
                )}

                {events.length <= 0 && (
                    <Flex
                        width="100%"
                        backgroundColor="yellow.200"
                        justifyContent="center"
                        alignItems="center"
                        height="40px"
                    >
                        <Text fontSize="14PX" fontWeight="600" color="gray.500">
                            Nenhum evento no momento
                        </Text>
                    </Flex>
                )}
            </Flex>
        </Flex>
    );
};

export default Dashboard;
