import { format } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Chart } from 'react-google-charts';
import { FiCalendar } from 'react-icons/fi';

import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Divider,
    Fade,
    Flex,
    Icon,
    Select,
    Stack,
    Text
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import apiBackend from '../../../../shared/apis';
import Calender from '../../../../shared/components/Calander';
import Form from '../../../../shared/components/Form';
import Input from '../../../../shared/components/Input';
import { HTTP_RESPONSE } from '../../../../shared/constants';
import { useLayout } from '../../../../shared/hooks/layout';

const RelatoriesPage: React.FC = () => {
    const { user } = useLayout();

    const formRef = useRef<FormHandles>(null);

    const [events, setEvents] = useState([] as any);
    const [defaultEvents, setDefaultEvents] = useState([] as any);

    const [data, setData] = useState([
        ['', 'Ruim', 'Indiferente', 'Bom', 'Muito Bom', 'Ótimo'],
        ['Resultado', 0, 0, 0, 0, 0]
    ]);

    const FIELDS = [
        {
            name: 'Evento',
            minWidth: '180px'
        },
        {
            name: 'Participantes',
            minWidth: '120px'
        },
        {
            name: 'Avaliações',
            minWidth: '120px'
        },
        {
            name: 'Ruim',
            minWidth: '120px'
        },
        {
            name: 'Indiferente',
            minWidth: '120px'
        },
        {
            name: 'Bom',
            minWidth: '120px'
        },
        {
            name: 'Muito Bom',
            minWidth: '120px'
        },
        {
            name: 'Ótimo',
            minWidth: '120px'
        },
        {
            name: 'Média',
            minWidth: '120px'
        }
    ];

    const [date, setDate] = useState(new Date());
    const [showCalendar, setShowCalendar] = useState(false);

    const handleChangeDeliveryDate = useCallback(async (value: Date) => {
        setDate(value);
        setShowCalendar(false);

        await apiBackend(user?.token)
            .get(`/events?date=${format(value, 'yyyy-MM-dd')}`)
            .then(response => {
                const { status, data } = response;

                if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                    setEvents(data);
                }
            });
    }, []);

    const handleSearch = useCallback(async (value: string) => {
        if (value) {
            await apiBackend(user?.token)
                .get(`/events?name=${value}`)
                .then(response => {
                    const { status, data } = response;

                    if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                        setEvents(data);
                    }
                });
        }
    }, []);

    const sumNotes = useCallback((eventsData: any) => {
        let note1 = 0;
        let note2 = 0;
        let note3 = 0;
        let note4 = 0;
        let note5 = 0;

        eventsData.forEach(item => {
            note1 += Number(item.oneCount);
            note2 += Number(item.twoCount);
            note3 += Number(item.threeCount);
            note4 += Number(item.fourCount);
            note5 += Number(item.fiveCount);
            console.log(item);
        });

        setData([
            ['', 'Ruim', 'Indiferente', 'Bom', 'Muito Bom', 'Ótimo'],
            ['Resultado', note1, note2, note3, note4, note5]
        ]);
    }, []);

    const handleLoadData = useCallback(async () => {
        await apiBackend(user?.token)
            .get('/relatories/events/ratings')
            .then(response => {
                const { status, data } = response;

                if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                    setEvents(data);
                    setDefaultEvents(data);

                    sumNotes(data);
                }
            });
    }, [user]);

    const handleChangeEventFilter = useCallback(
        (eventId: string) => {
            if (eventId) {
                const filtredEvents = defaultEvents.filter(
                    item => item.id === eventId
                );
                setEvents(filtredEvents);
                sumNotes(filtredEvents);
            } else {
                setEvents(defaultEvents);
                sumNotes(defaultEvents);
            }
        },
        [defaultEvents]
    );

    useEffect(() => {
        handleLoadData();
    }, []);

    return (
        <Form ref={formRef}>
            <Flex
                width="100%"
                flexDirection="column"
                onClick={() => {
                    if (showCalendar) {
                        setShowCalendar(false);
                    }
                }}
            >
                <Breadcrumb
                    mb="16px"
                    fontWeight="500"
                    color="gray.900"
                    fontSize="14px"
                >
                    <BreadcrumbItem>
                        <BreadcrumbLink href="/dashboard">
                            Início
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem isCurrentPage>
                        <BreadcrumbLink href="/events">Eventos</BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>

                <Flex
                    width="100%"
                    px="16px"
                    pt="16px"
                    justifyContent="space-between"
                    backgroundColor="white"
                    alignItems="center"
                    flexWrap={['wrap', 'wrap', 'nowrap']}
                >
                    <Flex justifyContent="space-between">
                        <Text fontWeight="600" color="green.500">
                            Relatório
                        </Text>
                    </Flex>

                    <Input
                        name="search"
                        placeholder="Pesquisar por Nome"
                        maxWidth="360px"
                        mb="0px"
                        onChange={e => handleSearch(e.currentTarget.value)}
                    />

                    <Flex width="360px">
                        <Select
                            name="events"
                            placeholder="Todos"
                            size="sm"
                            onChange={e =>
                                handleChangeEventFilter(e.currentTarget.value)
                            }
                        >
                            {defaultEvents.map(event => (
                                <option key={event.id} value={event.id}>
                                    {event.name}
                                </option>
                            ))}
                        </Select>
                    </Flex>

                    <Stack
                        direction="row"
                        alignItems="center"
                        display={['none', 'none', 'none', 'flex']}
                    >
                        <Text fontWeight="500" fontSize="14px" color="gray.500">
                            Filtrar por período
                        </Text>

                        <Flex
                            backgroundColor="gray.100"
                            justifyContent="center"
                            alignItems="center"
                            p="8px"
                            borderRadius="4px"
                            cursor="pointer"
                            onClick={() => setShowCalendar(!showCalendar)}
                        >
                            <Icon
                                as={FiCalendar}
                                color="green.500"
                                fontSize="18px"
                            />

                            {date && (
                                <Text
                                    ml="8px"
                                    fontSize="12px"
                                    fontWeight="500"
                                    color="gray.900"
                                >
                                    {format(date, 'dd/MM/yy')}
                                </Text>
                            )}
                        </Flex>

                        <Fade in={showCalendar}>
                            <Flex display={showCalendar ? 'flex' : 'none'}>
                                <Calender
                                    isErrored={false}
                                    selectedDate={date}
                                    handleDateChange={handleChangeDeliveryDate}
                                    handleMonthChange={undefined}
                                    onDayMouseUp={() => setShowCalendar(false)}
                                />
                            </Flex>
                        </Fade>
                    </Stack>
                </Flex>

                <Flex
                    width="100%"
                    flexDirection="column"
                    backgroundColor="white"
                    p="16px"
                >
                    <Chart
                        chartType="Bar"
                        width="98%"
                        height="400px"
                        data={data}
                    />

                    <Divider my="16px" />

                    <Flex width="100%" flexDirection="column" overflowX="auto">
                        <Flex width="100%">
                            {FIELDS.map(field => (
                                <Flex
                                    key={field.name}
                                    width="100%"
                                    minWidth={field.minWidth}
                                    fontSize="14px"
                                    fontWeight="600"
                                >
                                    <Text>{field.name}</Text>
                                </Flex>
                            ))}
                        </Flex>
                        <Flex
                            width="100%"
                            flexDirection="column"
                            fontSize="14px"
                        >
                            {events.map(event => (
                                <Flex
                                    key={event.id}
                                    width="100%"
                                    py="8px"
                                    borderBottom="1px solid"
                                    borderColor="gray.200"
                                >
                                    <Flex width="100%" maxWidth="100%">
                                        <Text>{event.name}</Text>
                                    </Flex>

                                    <Flex width="100%" maxWidth="100%">
                                        <Text>{event.num_guests}</Text>
                                    </Flex>

                                    <Flex width="100%" maxWidth="100%">
                                        <Text>{event.answers.length}</Text>
                                    </Flex>

                                    <Flex width="100%" maxWidth="100%">
                                        <Text>{event.oneCount}</Text>
                                    </Flex>

                                    <Flex width="100%" maxWidth="100%">
                                        <Text>{event.twoCount}</Text>
                                    </Flex>

                                    <Flex width="100%" maxWidth="100%">
                                        <Text>{event.threeCount}</Text>
                                    </Flex>

                                    <Flex width="100%" maxWidth="100%">
                                        <Text>{event.fourCount}</Text>
                                    </Flex>

                                    <Flex width="100%" maxWidth="100%">
                                        <Text>{event.fiveCount}</Text>
                                    </Flex>

                                    <Flex width="100%" maxWidth="100%">
                                        <Text>
                                            {Number(event.avg).toFixed(2)}
                                        </Text>
                                    </Flex>
                                </Flex>
                            ))}
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Form>
    );
};

export default RelatoriesPage;
