import React, { useCallback, useEffect, useState } from 'react';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Divider,
    Flex,
    Icon,
    Stack,
    Text,
    useDisclosure
} from '@chakra-ui/react';

import apiBackend from '../../../../shared/apis';
import Button from '../../../../shared/components/Button';
import Form from '../../../../shared/components/Form';
import ModalDeleteConfirmation from '../../../../shared/components/ModalDeleteConfirmation';
import { HTTP_RESPONSE } from '../../../../shared/constants';
import { useLayout } from '../../../../shared/hooks/layout';

const OpnionListPage: React.FC = () => {
    const { user } = useLayout();

    const navigate = useNavigate();

    const [searches, setSearches] = useState<any[]>([]);
    const [selectedItem, setSelectedItem] = useState('');
    const { isOpen, onOpen, onClose } = useDisclosure();

    const FIELDS = [
        {
            name: 'Pesquisa',
            maxWidth: '100%'
        },
        {
            name: 'Ação',
            maxWidth: '200px'
        }
    ];

    const deleteFunction = useCallback(async () => {
        await apiBackend(user?.token)
            .put(`/searches/${selectedItem}/delete`)
            .then(response => {
                const { status } = response;

                if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                    setSearches(oldState =>
                        oldState.filter(search => search.id !== selectedItem)
                    );
                }
            });
    }, [user, selectedItem]);

    const handleDelete = useCallback((value: string) => {
        setSelectedItem(value);
        onOpen();
    }, []);

    useEffect(() => {
        apiBackend(user?.token)
            .get(`/searches`)
            .then(response => {
                const { status, data } = response;

                if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                    setSearches(data);
                }
            });
    }, [user]);

    return (
        <Form>
            <Flex width="100%" flexDirection="column">
                <Breadcrumb
                    mb="16px"
                    fontWeight="500"
                    color="gray.900"
                    fontSize="14px"
                >
                    <BreadcrumbItem>
                        <BreadcrumbLink href="/dashboard">
                            Início
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem isCurrentPage>
                        <BreadcrumbLink href="/opnions">
                            Pesquisa de Opnião
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>

                <Flex
                    width="100%"
                    p="16px"
                    justifyContent="space-between"
                    backgroundColor="white"
                    alignItems="center"
                    flexDirection="column"
                >
                    <Flex width="100%" flexDirection="column">
                        <Button
                            title="+ Novo Modelo"
                            backgroundColor="green.500"
                            color="white"
                            width="192px"
                            minWidth="192px"
                            borderRadius="4px"
                            py="8px"
                            fontSize="14px"
                            onClick={() => navigate('/searches/new')}
                            mb="16px"
                        />

                        <Flex width="100%" justifyContent="space-between">
                            <Text fontWeight="600" color="green.500">
                                Pesquisas de Opnião
                            </Text>
                        </Flex>

                        <Divider my="16px" />

                        <Flex
                            width="100%"
                            flexDirection="column"
                            overflow="auto"
                        >
                            <Flex width="100%">
                                {FIELDS.map(field => (
                                    <Flex
                                        key={field.name}
                                        width="100%"
                                        maxWidth={field.maxWidth}
                                        fontSize="14px"
                                        fontWeight="600"
                                    >
                                        <Text>{field.name}</Text>
                                    </Flex>
                                ))}
                            </Flex>
                            <Flex
                                width="100%"
                                flexDirection="column"
                                fontSize="14px"
                            >
                                {searches.map(search => (
                                    <Flex key={search.id} width="100%" py="8px">
                                        <Flex width="100%" maxWidth="100%">
                                            <Text>{search.name}</Text>
                                        </Flex>

                                        <Flex width="100%" maxWidth="200px">
                                            <Stack direction="row">
                                                <Flex
                                                    width="24px"
                                                    height="24px"
                                                    backgroundColor="gray.900"
                                                    color="white"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    title="Editar"
                                                    cursor="pointer"
                                                    borderRadius="50%"
                                                    onClick={() =>
                                                        navigate(
                                                            `/searches/edit/${search.id}`
                                                        )
                                                    }
                                                >
                                                    <Icon
                                                        as={FiEdit}
                                                        fontSize="18px"
                                                        color="white"
                                                    />
                                                </Flex>

                                                <Flex
                                                    width="24px"
                                                    height="24px"
                                                    backgroundColor="red.500"
                                                    color="white"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    title="Excluir"
                                                    cursor="pointer"
                                                    borderRadius="50%"
                                                    onClick={() =>
                                                        handleDelete(search.id)
                                                    }
                                                >
                                                    <Icon
                                                        as={FiTrash2}
                                                        fontSize="18px"
                                                    />
                                                </Flex>
                                            </Stack>
                                        </Flex>
                                    </Flex>
                                ))}
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
                <ModalDeleteConfirmation
                    deleteFunction={deleteFunction}
                    isOpen={isOpen}
                    onClose={onClose}
                />
            </Flex>
        </Form>
    );
};

export default OpnionListPage;
