export default class TextUtils {
    static removeCharacters(value: string) {
        const newValue = value.replace(/[^a-zA-Z0-9]/g, '');
        return newValue;
    }

    static convertEventStatus(value: string) {
        switch (value) {
            case 'STARTED':
                return 'Iniciado';
            case 'CANCELED':
                return 'Cancelado';
            case 'FINISHED':
                return 'Finalizado';
            default:
                return 'Aguardando Início';
        }
    }

    static convertStatus(value: string) {
        switch (value) {
            case 'ENABLED':
                return 'Ativo';
            default:
                return 'Inativo';
        }
    }

    static convertStatusColor(value: string) {
        switch (value) {
            case 'ENABLED':
                return 'green.500';
            default:
                return 'red.500';
        }
    }

    static convertEventStatusColor(value: string) {
        switch (value) {
            case 'STARTED':
                return 'green.500';
            case 'CANCELED':
                return 'red.500';
            case 'FINISHED':
                return 'purple.500';
            default:
                return 'yellow.500';
        }
    }
}
